import useH3DStore from '../../../modules/useH3DStore'
import { getCurrentModel } from '../../../modules/modelStore'
import { rgb2Hex_3 } from '../../../utils/utils'

export const AmbientLight = () => {
  const currentModel = useH3DStore(getCurrentModel)

  return true || currentModel?.ambientLight.on ? (
    <hemisphereLight
      intensity={currentModel.ambientLight.intensity}
      color={rgb2Hex_3(
        currentModel.ambientLight.color.r * 255,
        currentModel.ambientLight.color.g * 255,
        currentModel.ambientLight.color.b * 255
      )}
    />
  ) : null
}

export default AmbientLight
