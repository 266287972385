import { createSelector } from "reselect";
import * as THREE from "three";
import { clamp } from "../utils/utils";

const ANGLE_INC = 0.1;
const TIME_STEP = 200;
const v3 = new THREE.Vector3();
const v3_2 = new THREE.Vector3(0, 1, 0);
export const lightStore = (set, get) => ({
  light: {
    target: [0, 0, 0],
    angle: 0, //[0-Math.PI]
    arcRadius: 100, //[0.01-Infinity]
    arcAzimuth: 0, //[0-2*Math.PI]
    targetArcDistance: 100,
    animate: false,
    timerId: null,

    setTarget: (x, y, z) => {
      set((prev) => ({ light: { ...prev.light, target: [x, y, z] } }));
    },
    setTargetArcDistance: (value) => {
      set((prev) => ({
        light: { ...prev.light, targetArcDistance: value },
      }));
    },
    setArcRadius: (value) => {
      set((prev) => ({
        light: { ...prev.light, targetArcDistance: Math.max(value, 0.01) },
      }));
    },
    setArcAzimuth: (value) => {
      set((prev) => ({
        light: {
          ...prev.light,
          targetArcDistance: clamp(value, 0, 2 * Math.PI),
        },
      }));
    },
    setAngle: (angle) => {
      if (animateLightSelector(get())) {
        angle = angle > Math.PI ? 0 : angle;
      } else {
        angle = clamp(angle, 0, Math.PI);
      }
      if (angle !== lightAngleSelector(get())) {
        set((prev) => ({ light: { ...prev.light, angle } }));
      }
    },
    setPlay: (animate) => {
      if (animate !== animateLightSelector(get())) {
        let id = null;
        if (animate) {
          if (!timerIdSelector(get())) {
            id = setInterval(() => {
              setLightAngleAction(get())(lightAngleSelector(get()) + ANGLE_INC);
            }, TIME_STEP);
          }
        } else {
          clearInterval(timerIdSelector(get()));
        }
        set((prev) => ({ light: { ...prev.light, animate, timerId: id } }));
      }
    },
    play: () => {
      setAnimateLightAction(get())(true);
    },
    stop: () => {
      setAnimateLightAction(get())(false);
    },
    toggle: () => {
      setAnimateLightAction(get())(!animateLightSelector(get()));
    },
  },
});
export default lightStore;

export const setLightTargetAction = (state) => state.light.setTarget;
export const setLightArcRadiusAction = (state) => state.light.setArcRadius;
export const setLightTargetArcDistanceAction = (state) =>
  state.light.setTargetArcDistance;
export const setLightAngleAction = (state) => state.light.setAngle;
const setAnimateLightAction = (state) => state.light.setPlay;
export const stopLightAnimationAction = (state) => state.light.stop;
export const startLightAnimationAction = (state) => state.light.play;
export const toggleLightAnimationAction = (state) => state.light.toggle;

const timerIdSelector = (state) => state.light.timerId;
export const animateLightSelector = (state) => state.light.animate;
export const lightAngleSelector = (state) => state.light.angle;
export const lightTargetSelector = (state) => state.light.target;
export const arcAzimuthSelector = (state) => state.light.arcAzimuth;
export const arcRadiusSelector = (state) => state.light.arcRadius;
export const targetArcDistanceSelector = (state) =>
  state.light.targetArcDistance;

export const getLigtPosition = createSelector(
  [
    lightAngleSelector,
    lightTargetSelector,
    targetArcDistanceSelector,
    arcAzimuthSelector,
    arcRadiusSelector,
  ],
  (angle, target, distance, arcAzimuth, radius) => {
    v3.set(radius * Math.cos(angle), radius * Math.sin(angle), distance);
    v3.applyAxisAngle(v3_2, arcAzimuth);
    return [target[0] + v3.x, target[1] + v3.y, target[2] + v3.z];
  }
);
