export const findById = (data, id) => {
  return data ? data.find((d) => d.id === id) : null
}

export const formatNumber = (num) => {
  return new Intl.NumberFormat('es-ES').format(num)
  // return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export const loadImagePromise = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve(img)
    }
    img.onerror = img.onabort = (e) => {
      console.log(e)
      resolve(null)
    }
    img.src = src
  })
}

export const selectItem = (itemsIdArray, itemId, select) => {
  let newItemsIdArray = [...itemsIdArray]
  if (select) {
    if (newItemsIdArray.indexOf(itemId) < 0) {
      newItemsIdArray.push(itemId)
    }
  } else {
    newItemsIdArray = newItemsIdArray.filter((id) => id !== itemId)
  }
  return newItemsIdArray
}

export const clamp = (value, min, max) => {
  if (max < min) {
    return min
  }
  return Math.min(Math.max(min, value), max)
}

//Valores [0-255]
export const rgb2Hex_3 = (r, g, b) => {
  return 65536 * parseInt(r) + 256 * parseInt(g) + parseInt(b)
}

export const addVectors = (v0, v1) => ({ x: v0.x + v1.x, y: v0.y + v1.y })

const pad = (value) => {
  return value.length < 2 ? '0' + value : value
}
//Valores [0-255]
export const rgba2Hex_2 = (r, g, b, a) => {
  return (
    '#' +
    pad(r ? parseInt(r).toString(16) : '0') +
    pad(g ? parseInt(g).toString(16) : '0') +
    pad(b ? parseInt(b).toString(16) : '0') +
    pad(a ? parseInt(a).toString(16) : '0')
  )
}

export const getChildOffset = (scale, translation, childSize, parentBounds) => {
  if (!parentBounds) {
    return { x: 0, y: 0 }
  }
  return {
    x: Math.round(
      translation.x + (parentBounds.width - childSize.width * scale) / 2
    ),
    y: Math.round(
      translation.y + (parentBounds.height - childSize.height * scale) / 2
    )
  }
}

export const drawShape = (
  ctx,
  polygons,
  fill,
  fillCSSColor,
  stroke = false,
  strokeCSSColor = 'black',
  strokeWidth = 1
) => {
  ctx.lineWidth = strokeWidth
  ctx.lineJoin = 'round'
  ctx.beginPath()
  for (let polygon of polygons) {
    if (polygon.length > 0 && (fill || stroke)) {
      ctx.moveTo(polygon[0][0], polygon[0][1])
      for (let i = 1; i < polygon.length; i++) {
        ctx.lineTo(polygon[i][0], polygon[i][1])
      }
      ctx.closePath()
    }
  }
  if (fill) {
    ctx.fillStyle = fillCSSColor
    ctx.fill('evenodd')
  }
  if (stroke) {
    ctx.strokeStyle = strokeCSSColor
    ctx.stroke()
  }
}

export const getChildTopLeft = (
  scale,
  translation,
  childSize,
  parentBounds
) => {
  if (!parentBounds) {
    return { x: 0, y: 0 }
  }
  const offset = getChildOffset(scale, translation, childSize, parentBounds)
  return getChildTopLeft2(offset, parentBounds)
}

export const getChildTopLeft2 = (offset, parentBounds) => {
  if (!parentBounds) {
    return { x: 0, y: 0 }
  }
  return {
    x: parentBounds.left + offset.x,
    y: parentBounds.top + offset.y
  }
}

export const isPointInsideBounds = (x, y, bounds) => {
  if (!bounds) {
    return false
  }
  const xOffset = 20 //Esta por el popper, para que no se salga
  return (
    x > bounds.x + xOffset &&
    y > bounds.y &&
    x < bounds.x + bounds.width - xOffset &&
    y < bounds.y + bounds.height
  )
}

export const localPointToGlobalPoint = (localPoint, scale, childTopLeft) => {
  return {
    x: Math.round((childTopLeft ? childTopLeft.x : 0) + localPoint.x * scale),
    y: Math.round((childTopLeft ? childTopLeft.y : 0) + localPoint.y * scale)
  }
}

export const globalPointToLocalPoint = (globalPoint, scale, childTopLeft) => {
  return {
    x: Math.round((globalPoint.x - childTopLeft.x) / scale),
    y: Math.round((globalPoint.y - childTopLeft.y) / scale)
  }
}

export const vector = (x = 0, y = 0) => ({ x: x, y: y })

export const boxCenter = (top, left, width, height) => {
  return vector(left + width / 2, top + height / 2)
}

export const getFitChildScale = (childSize, parentSize, portrait = true) => {
  if (
    !childSize ||
    !parentSize ||
    childSize.width === 0 ||
    childSize.height === 0 ||
    parentSize.width === 0 ||
    parentSize.height === 0
  ) {
    return 1
  }
  return Math.min(
    parentSize.width / (portrait ? childSize.width : childSize.height),
    parentSize.height / (portrait ? childSize.height : childSize.width)
  )
}

export const getCoverChildScale = (childSize, parentSize, portrait = true) => {
  if (
    !childSize ||
    !parentSize ||
    childSize.width === 0 ||
    childSize.height === 0 ||
    parentSize.width === 0 ||
    parentSize.height === 0
  ) {
    return 1
  }
  return Math.max(
    parentSize.width / (portrait ? childSize.width : childSize.height),
    parentSize.height / (portrait ? childSize.height : childSize.width)
  )
}

export const zoomChildAtGlobalPoint = (
  currentScale,
  scaleRange,
  currentTranslation,
  factor,
  globalPoint,
  parentBounds
) => {
  //Nueva escala
  const newScale = clamp(currentScale * factor, scaleRange[0], scaleRange[1])

  if (newScale === currentScale || !parentBounds) {
    //Nada que hacer
    return { scale: newScale, translation: currentTranslation }
  }

  const imageGlobalCenter = getChildCenter(parentBounds, currentTranslation)
  //Punto con respecto centro del child
  const zoomPoint = vectorFromPoints(imageGlobalCenter, globalPoint)
  //Quita escalado
  const zoomPointOriginal = scaleVector(zoomPoint, 1 / currentScale)
  //Coordenadas del punto con la nueva escala
  const newZoomPoint = scaleVector(zoomPointOriginal, newScale)
  //Incremento entre los dos puntos
  let inc = vectorFromPoints(zoomPoint, newZoomPoint)
  //Normaliza
  let newTranslation = vectorFromPoints(inc, currentTranslation)
  return { scale: newScale, translation: newTranslation }
}

export const centerAtLocalPoint = (
  localPoint,
  currentTranslation,
  scale,
  childSize,
  parentBounds
) => {
  const parentCenter = boxCenter(
    parentBounds.top,
    parentBounds.left,
    parentBounds.width,
    parentBounds.height
  )
  const centerPoint = localPointToGlobalPoint(
    localPoint,
    scale,
    currentTranslation,
    childSize,
    parentBounds
  )
  const inc = vectorFromPoints(centerPoint, parentCenter)
  return addVectors(currentTranslation, inc)
}

export const getChildCenter = (parentBounds, translation) => {
  return parentBounds
    ? addVectors(
        boxCenter(
          parentBounds.top,
          parentBounds.left,
          parentBounds.width,
          parentBounds.height
        ),
        translation
      )
    : { x: 0, y: 0 }
}

export const vectorFromPoints = (startPoint, endPoint) => {
  return { x: endPoint.x - startPoint.x, y: endPoint.y - startPoint.y }
}

export const scaleVector = (v, scale) => {
  return { x: v.x * scale, y: v.y * scale }
}
