import { useLayoutEffect } from 'react'
import { invalidate } from '@react-three/fiber'
// import {
//   pz_childSizeSelector,
//   pz_fitAction,
//   PanZoom,
//   PanZoomImage,
//   pz_setChildFitModeAction,
//   pz_setTargetTranslationAction,
//   usePanZoom,
//   CHILD_FIT_MODE
// } from '@aza/components'
import { getCurrentHouse } from '../../modules/promotionStore'
import useH3DStore from '../../modules/useH3DStore'
import usePanZoom from '../PanZoom/modules/usePanZoom'
import { CHILD_FIT_MODE } from '../PanZoom/panZoomConstants'
import {
  pz_childSizeSelector,
  pz_fitAction,
  pz_setChildFitModeAction,
  pz_setTargetTranslationAction
} from '../PanZoom/modules/panZoomStore'
import PanZoom from '../PanZoom/PanZoom'
import PanZoomImage from '../PanZoom/PanZoomImage'

const Viewer = () => {
  const setChildFitMode = usePanZoom(pz_setChildFitModeAction)
  const setTargetTranslation = usePanZoom(pz_setTargetTranslationAction)
  const fit = usePanZoom(pz_fitAction)
  const childSize = usePanZoom(pz_childSizeSelector)
  const currentHouse = useH3DStore(getCurrentHouse)

  useLayoutEffect(() => {
    setChildFitMode(CHILD_FIT_MODE.CONTAIN)
    setTargetTranslation({ x: 0, y: 0 })
  }, [setChildFitMode, setTargetTranslation])

  useLayoutEffect(() => {
    if (childSize?.width > 0 && childSize.height > 0) {
      fit()
    }
  }, [childSize, fit])

  return (
    <PanZoom
      id={'plans'}
      style={{ width: '100%', height: '100%' }}
      invalidate={invalidate}
    >
      <PanZoomImage
        src={currentHouse.map}
        style={{ backgroundColor: 'white' }}
      />
    </PanZoom>
  )
}
export default Viewer
