import { useRef, useState, useEffect, useMemo } from 'react'

import { useGraph } from '@react-three/fiber'

import {
  backgroundActiveSelector,
  filterActiveSelector
} from '../../../../modules/mainStore'

import useH3DStore from '../../../../modules/useH3DStore'
import { getFilterHouses } from '../../../../modules/filterStore'
import { Euler, Quaternion, Vector3 } from 'three'
import House from './House'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'

export const HousesFBX = ({ src, name, envMap, onLoad, withPrinex }) => {
  const v3 = useRef(new Vector3())
  const q = useRef(new Quaternion())
  const euler = useRef(new Euler())

  const loader = useRef(new FBXLoader())
  const [scene, setScene] = useState(null)

  useEffect(() => {
    const loadData = async () => {
      try {
        const scene = await loader.current.loadAsync(src)
        setScene(scene)
      } catch (error) {
        console.log(error)
      }
    }
    loadData()
  }, [src])

  const { nodes: originalNodes } = useGraph(scene)

  useEffect(() => {
    if (scene) {
      console.log(name)
      onLoad?.()
    }
  }, [scene, onLoad, name])

  const backgroundActive = useH3DStore(backgroundActiveSelector)
  const filterActive = useH3DStore(filterActiveSelector)
  const filterHouses = useH3DStore(getFilterHouses)
  const filterNodes = useMemo(() => {
    // console.log(filterHouses.map((i) => i.nodeId))
    // console.log(Object.values(originalNodes).map((i) => i.name))
    return Object.values(originalNodes).reduce((acc, node) => {
      // console.log(node.name)
      const house = filterHouses.find((h) => {
        return h.nodeId === node.name
      })
      //Calcula la posicion aqui, porque el nodo puede ser hijo de varios grupos
      //cada uno con su propia posicion, por lo que al final la posicion no es
      //la que trae node. Igual con rotation y scale
      node.getWorldPosition(v3.current)
      const position = v3.current.toArray()
      node.getWorldQuaternion(q.current)
      euler.current.setFromQuaternion(q.current)
      const rotation = euler.current.toArray()
      node.getWorldScale(v3.current)
      const scale = v3.current.toArray()
      acc.push({
        node,
        house,
        position,
        rotation,
        scale
      })
      return acc
    }, [])
  }, [originalNodes, filterHouses])

  // console.log(
  //   scene,
  //   // scene.children.map((i) => i.name),
  //   // new Set(scene.children.map((i) => i.name)),
  //   scene.children.reduce(
  //     (acc, i) => {
  //       if (!acc.unique.find((name) => name === i.name)) {
  //         acc.unique.push(i.name)
  //       } else {
  //         acc.repeat.push(i.name)
  //       }
  //       return acc
  //     },
  //     { unique: [], repeat: [] }
  //   )
  // )

  // const { repeat } = scene.children.reduce(
  //   (acc, i) => {
  //     if (!acc.unique.find((name) => name === i.name)) {
  //       acc.unique.push(i.name)
  //     } else {
  //       acc.repeat.push(i.name)
  //     }
  //     return acc
  //   },
  //   { unique: [], repeat: [] }
  // )
  // console.log(repeat)
  // generatePanoramicHousesFromThreeNodes(Object.values(originalNodes))
  // console.log(
  //   { originalNodes, filterNodes, filterHouses, filterActive },
  //   Object.values(originalNodes)
  // )
  // return <primitive object={scene} />
  return filterActive && scene
    ? filterNodes.map((data) => {
        // console.log(data)
        return (
          <House
            key={data.node.name}
            node={data.node}
            house={data.house}
            position={data.position}
            rotation={data.rotation}
            scale={data.scale}
            envMap={envMap}
            backgroundActive={backgroundActive}
            withPrinex={withPrinex}
          />
        )
      })
    : null
}

export default HousesFBX
