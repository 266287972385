import { useEffect, useRef, useState } from 'react'

import { useGraph } from '@react-three/fiber'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { TextureLoader } from 'three'
import { updateTextureValueAfterLoad } from './useLoadAllModelTextures'

export const Jardinera = ({
  nodes,
  envMap,
  backgroundHidden,
  onLoad,
  ...props
}) => {
  const loader = useRef(new FBXLoader())
  const [scene, setScene] = useState(null)
  const textureLoader = useRef(new TextureLoader())
  const [map, setMap] = useState(null)

  useEffect(() => {
    const loadData = async () => {
      try {
        let t = await textureLoader.current.loadAsync(
          'models/neinor_panoramic/model/jardineras/map.jpg'
        )
        updateTextureValueAfterLoad(t, 1, 1)
        setMap(t)

        const url = 'models/neinor_panoramic/model/jardineras/model.fbx'
        const scene = await loader.current.loadAsync(url)
        setScene(scene)
      } catch (error) {
        console.log(error)
      }
    }
    loadData()
  }, [])

  const { nodes: originalNodes } = useGraph(scene)

  useEffect(() => {
    if (scene) {
      console.log('Jardinera')
      onLoad?.()
    }
  }, [scene, onLoad])

  return Object.keys(originalNodes).map((nodeId) => {
    return (
      <mesh
        key={nodeId}
        geometry={originalNodes[nodeId].geometry}
        position={originalNodes[nodeId].position}
        rotation={originalNodes[nodeId].rotation}
      >
        <meshBasicMaterial map={map} />
      </mesh>
    )
  })
}
