import { styled } from '@mui/material/styles'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'vertical'
})(({ theme, vertical }) => ({
  boxSizing: 'border-box',
  padding: '12px 16px 8px 16px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',

  borderBottom: vertical ? `2px solid ${theme.palette.secondary.main}` : null,
  borderRight: vertical ? null : `2px solid ${theme.palette.secondary.main}`,
  '&:last-of-type': {
    border: 'none'
  }
}))

const Title = styled('div')(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: 20,
  fontWeight: 600
}))

const Cell = ({ title, vertical, children }) => {
  return (
    <Root vertical={vertical}>
      <Title>{title}</Title>
      {children}
    </Root>
  )
}

export default Cell
