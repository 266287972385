import { styled } from '@mui/material/styles'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mobile' && prop !== 'textAlign'
})(({ theme, mobile, textAlign }) => ({
  flex: '0 0 auto',
  userSelect: 'none',
  fontSize: mobile ? 16 : 24,
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign,
  fontFamily: `ITCAvantGardeStd-Md, Neinor ,Roboto, Helvetica, Arial, sans-serif`,
  fontWeight: 400
}))

export const Title = ({ textAlign, mobile, children }) => {
  return (
    <Root textAlign={textAlign} mobile={mobile}>
      {children}
    </Root>
  )
}
export default Title
