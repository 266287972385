import { styled } from '@mui/material/styles'
import {
  dialogIdSelector,
  filterActiveSelector,
  infoPanelSelector,
  isMobile,
  lightActiveSelector
} from '../../modules/mainStore'
import {
  currentHouseSelector,
  getCurrentPromotion
} from '../../modules/promotionStore'
import useH3DStore from '../../modules/useH3DStore'

import BackgroundButton from './BackgroundButton'

import FilterButton from './FilterButton'
import FullscreenButton from './FullscreenButton'
// import LightButton from './LightButton'
import LocalizationButton from './LocalizationButton'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mobile'
})(({ theme, mobile }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.overlay,
  top: mobile ? 4 : 16,
  right: mobile ? 4 : 24
}))

export const Toolbar = () => {
  const promotion = useH3DStore(getCurrentPromotion)
  const mobile = useH3DStore(isMobile)
  const currentHouse = useH3DStore(currentHouseSelector)
  const filterActive = useH3DStore(filterActiveSelector)
  const lightActive = useH3DStore(lightActiveSelector)
  const infoPanel = useH3DStore(infoPanelSelector)
  const dialogId = useH3DStore(dialogIdSelector)
  const show =
    !dialogId &&
    promotion &&
    (!promotion.withPrinex ||
      (promotion.withPrinex &&
        !currentHouse &&
        !filterActive &&
        !infoPanel &&
        !lightActive))

  return show ? (
    <Root mobile={mobile}>
      <FilterButton />
      {/* <BackgroundButton /> */}
      {/* <LocalizationButton /> */}
      {/* <LightButton /> */}
      <FullscreenButton mobile={mobile} />
    </Root>
  ) : null
  // return !currentHouse && !filterActive && !infoPanel && !lightActive ? (
  //   <Root mobile={mobile}>
  //     <FilterButton />
  //     <BackgroundButton />
  //     {/* <LocalizationButton /> */}
  //     {/* <LightButton /> */}
  //     <FullscreenButton mobile={mobile} />
  //   </Root>
  // ) : null
}
export default Toolbar
