import { PerspectiveCamera } from '@react-three/drei'

import { getCurrentModel } from '../../modules/modelStore'
import useH3DStore from '../../modules/useH3DStore'

const Camera = ({ ...props }) => {
  const currentModel = useH3DStore(getCurrentModel)
  return currentModel ? (
    <PerspectiveCamera
      position={currentModel.camera.position}
      near={currentModel.camera.near}
      far={currentModel.camera.far}
      makeDefault
      regress
      {...props}
    />
  ) : null
}

export default Camera
