import create from 'zustand'
import { devtools } from 'zustand/middleware'

import panZoomStore from './panZoomStore'

export const usePanZoom = create(
  devtools(
    (set, get) => ({
      ...panZoomStore(set, get)
    }),
    { name: 'Store' }
  )
)

export default usePanZoom
