import { createSelector } from 'reselect'

export const MAIN_MODE = {
  DISABLED: 'DISABLED',
  INITIALIZED: 'INITIALIZED',
  LOADING_MODEL: 'LOADING_MODEL',
  LOADING_MODEL_2: 'LOADING_MODEL_2',
  VIEW_3D_LOADING_DATA: 'VIEW_3D_LOADING_DATA',
  VIEW_3D: 'VIEW_3D',
  PLAN: 'PLAN',
  PANO: 'PANO'
}
export const mainStore = (set, get) => ({
  main: {
    bounds: null,
    initialized: false,
    fullscreen: false,
    mode: MAIN_MODE.DISABLED,
    filterActive: false,
    dialogId: '',
    background: true,
    places: false,
    infoPanel: false,
    lightActive: false,
    progress: 0,

    setBounds: (bounds) => {
      set((prev) => ({ main: { ...prev.main, bounds } }))
    },
    setFullscreen: (fullscreen) => {
      set((prev) => ({ main: { ...prev.main, fullscreen } }))
    },
    setMode: (mode) => {
      if (mode !== mainModeSelector(get())) {
        set((prev) => ({ main: { ...prev.main, mode } }))
      }
    },
    setFilterActive: (active) => {
      set((prev) => ({
        main: { ...prev.main, filterActive: active, dialogId: '' }
      }))
    },
    setDialogId: (dialogId) => {
      set((prev) => ({
        main: { ...prev.main, dialogId, filterActive: false, infoPanel: false }
      }))
    },
    setLightActive: (active) => {
      set((prev) => ({ main: { ...prev.main, lightActive: active } }))
    },
    setBackgroundActive: (active) => {
      set((prev) => ({ main: { ...prev.main, background: active } }))
      if (!active) {
        setPlacesActiveAction(get())(false)
      }
    },
    setPlacesActive: (active) => {
      if (backgroundActiveSelector(get()) || !active) {
        set((prev) => ({ main: { ...prev.main, places: active } }))
      }
    },
    setInfoPanel: (panelId) => {
      set((prev) => ({ main: { ...prev.main, infoPanel: panelId } }))
    },
    setProgress: (value) => {
      set((prev) => ({ main: { ...prev.main, progress: value } }))
    }
  }
})
export default mainStore

// export const initAppAction = (state) => state.main.init
export const setRootBoundsAction = (state) => state.main.setBounds
export const setFullscreenAction = (state) => state.main.setFullscreen
export const setMainModeAction = (state) => state.main.setMode
export const setFilterActiveAction = (state) => state.main.setFilterActive
export const setDialogIdAction = (state) => state.main.setDialogId
export const setLightActiveAction = (state) => state.main.setLightActive
export const setBackgroundActiveAction = (state) =>
  state.main.setBackgroundActive
export const setPlacesActiveAction = (state) => state.main.setPlacesActive
export const setInfoPanelAction = (state) => state.main.setInfoPanel
export const setProgressAction = (state) => state.main.setProgress

export const boundsSelector = (state) => state.main.bounds
export const fullscreenSelector = (state) => state.main.fullscreen
export const mainModeSelector = (state) => state.main.mode
export const filterActiveSelector = (state) => state.main.filterActive
export const dialogIdSelector = (state) => state.main.dialogId
export const lightActiveSelector = (state) => state.main.lightActive
export const backgroundActiveSelector = (state) => state.main.background
export const placesActiveSelector = (state) => state.main.places
export const infoPanelSelector = (state) => state.main.infoPanel
export const progressSelector = (state) => state.main.progress

export const isMobile = createSelector([boundsSelector], (bounds) => {
  return (
    bounds?.width > 0 &&
    bounds.height > 0 &&
    (bounds.width < 800 || bounds.height < 800)
  )
})
