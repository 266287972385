import { useEffect, useRef, useState } from 'react'

import { useGraph } from '@react-three/fiber'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { MaterialBasic2 } from '../../MaterialBasic2'
import { useLoadAllModelTextures } from './useLoadAllModelTextures'

export const Torre1 = ({
  nodes,
  envMap,
  backgroundHidden,
  onLoad,
  ...props
}) => {
  const loader = useRef(new FBXLoader())
  const [scene, setScene] = useState(null)
  const textures = useLoadAllModelTextures(nodes)

  useEffect(() => {
    const loadData = async () => {
      if (textures) {
        try {
          const url = 'models/neinor_panoramic/model/torre_1/model.fbx'
          const scene = await loader.current.loadAsync(url)
          setScene(scene)
        } catch (error) {
          console.log(error)
        }
      }
    }
    loadData()
  }, [textures])

  const { nodes: originalNodes } = useGraph(scene)

  useEffect(() => {
    if (scene) {
      console.log('Torre1')
      onLoad?.()
    }
  }, [scene, onLoad])
  //   return <primitive object={scene} {...props} />

  //   const { nodes, materials } = useGLTF('/torre4.glb')
  // console.log(nodes, materials)
  // return null
  return scene
    ? Object.keys(originalNodes).map((nodeId) => {
        return nodes[nodeId].visible ? (
          <mesh
            key={nodeId}
            castShadow
            receiveShadow
            geometry={originalNodes[nodeId].geometry}
            position={originalNodes[nodeId].position}
            rotation={originalNodes[nodeId].rotation}
            onClick={() => {}}
          >
            <MaterialBasic2
              original={originalNodes[nodeId].material}
              modified={
                nodes[nodeId]?.material || originalNodes[nodeId].material
              }
              map={textures[nodeId]?.map || null}
              alphaMap={textures[nodeId]?.alphaMap || null}
              backgroundHidden={false}
              envMap={envMap}
            />
          </mesh>
        ) : null
      })
    : null
  // return Object.keys(originalNodes).map((nodeId) => {
  //   return nodes[nodeId].visible ? (
  //     <mesh
  //       key={nodeId}
  //       castShadow
  //       receiveShadow
  //       geometry={originalNodes[nodeId].geometry}
  //       position={originalNodes[nodeId].position}
  //       rotation={originalNodes[nodeId].rotation}
  //       onClick={() => {}}
  //     >
  //       <MaterialBasic
  //         original={originalNodes[nodeId].material}
  //         modified={nodes[nodeId]?.material || originalNodes[nodeId].material}
  //         backgroundHidden={false}
  //         envMap={envMap}
  //       />
  //     </mesh>
  //   ) : null
  // })
  // return scene ? <primitive object={scene} /> : null
}
