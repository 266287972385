import NeinorPanoramic from './Models/NeinorPanoramic/NeinorPanoramic'

export const ModelFBX = ({ id, envMap }) => {
  switch (id) {
    case 'neinor_panoramic':
      return <NeinorPanoramic envMap={envMap} />
    default:
      return null
  }
}
export default ModelFBX
