import { useCallback } from 'react'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'
import useH3DStore from '../../modules/useH3DStore'
import { MAIN_MODE, setMainModeAction } from '../../modules/mainStore'

const Root = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'mobile'
})(({ theme, mobile }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  borderRadius: '0px',
  width: '50%',
  height: mobile ? 32 : 64,
  marginTop: '4px',
  fontSize: mobile ? 12 : 18,
  padding: 0,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}))

const PlanButton = ({ mobile }) => {
  const setMainMode = useH3DStore(setMainModeAction)

  const handleClick = useCallback(() => {
    setMainMode(MAIN_MODE.PLAN)
  }, [setMainMode])
  return (
    <Root
      variant="contained"
      onClick={handleClick}
      mobile={mobile}
      disableRipple
    >
      Ver plano
    </Root>
  )
}

export default PlanButton
