import { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { mergeRefs } from 'react-merge-refs'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { useGesture } from '@use-gesture/react'
import { useSpring } from '@react-spring/web'

import usePanZoom from './modules/usePanZoom'
import {
  pz_translationSelector,
  pz_setTranslationAction,
  pz_scaleSelector,
  pz_setBoundsAction,
  pz_resetPanZoomAction,
  pz_childSizeSelector,
  pz_zoomDisabledSelector,
  pz_panDisabledSelector,
  pz_targetScaleSelector,
  pz_targetTranslationSelector,
  pz_setScaleAction,
  pz_zoomAtGlobalPointAction,
  pz_setTargetTranslationAction,
  pz_zoomAtCenterAction
} from './modules/panZoomStore'
import { addVectors } from '../../utils/utils'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'cursor'
})(({ theme, cursor }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  touchAction: 'none',
  userSelect: 'none',
  cursor,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))
const TooltipBoundaries = styled('div', {
  shouldForwardProp: (prop) => prop !== 'tooltipsMargins'
})(({ theme, tooltipsMargins }) => ({
  position: 'absolute',
  top: tooltipsMargins.top,
  left: tooltipsMargins.left,
  bottom: tooltipsMargins.bottom,
  right: tooltipsMargins.right
}))

const getCursor = (panning, panDisabled, cursor) => {
  if (cursor) {
    return cursor
  }
  return panDisabled ? '' : panning ? 'grabbing' : 'grab'
}

export const PanZoom = ({
  wheelDisabled = false,
  cursor,
  onMouseMove, //state
  onHover, //state
  onDragStart, //state
  onDrag, //state
  onDragEnd, //state
  onPanningStart,
  onPanningEnd,
  onClick, //{x,y}
  onZoom,
  onTranslate,
  onWheel, //{delta,x,y}
  onBounds,
  onChildSize,

  children,
  animate = true,
  zoomOnClick = true,
  tooltipsMargins = null, // { top: 0, right: 0, bottom: 0, left: 0 },
  boundariesRef,

  invalidate,
  ...props
}) => {
  const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })
  const ref = useRef(null)
  const panDisabled = usePanZoom(pz_panDisabledSelector)
  const zoomDisabled = usePanZoom(pz_zoomDisabledSelector)
  const scale = usePanZoom(pz_scaleSelector)
  const setScale = usePanZoom(pz_setScaleAction)
  const translation = usePanZoom(pz_translationSelector)
  const targetScale = usePanZoom(pz_targetScaleSelector)
  const targetTranslation = usePanZoom(pz_targetTranslationSelector)
  const setTargetTranslation = usePanZoom(pz_setTargetTranslationAction)
  const setBounds = usePanZoom(pz_setBoundsAction)
  const resetPanZoom = usePanZoom(pz_resetPanZoomAction)
  const setTranslation = usePanZoom(pz_setTranslationAction)
  const childSize = usePanZoom(pz_childSizeSelector)
  const zoomAtGlobal = usePanZoom(pz_zoomAtGlobalPointAction)
  const zoomAtCenter = usePanZoom(pz_zoomAtCenterAction)

  const startTranslation = useRef(translation)

  const [panning, setPanning] = useState(false)
  const lastPinchDistanceRef = useRef(0)

  useEffect(() => {
    onZoom?.(scale)
  }, [onZoom, scale])

  useEffect(() => {
    onTranslate?.(translation)
  }, [onTranslate, translation])

  useEffect(() => {
    onChildSize?.(childSize)
  }, [onChildSize, childSize])

  //Cambio tamaño contenedor
  useEffect(() => {
    if (rootBounds && rootBounds.width > 0 && rootBounds.height > 0) {
      setBounds(rootBounds)
      onBounds?.(rootBounds)
    }
  }, [setBounds, resetPanZoom, rootBounds, onBounds])

  const handleWheel = (e) => {
    //Se usa onWheel en lugar de useGesture porque en useGesture no se puede
    //leer en que punto esta el cursor cuando se activa la rueda

    if (e.deltaY !== 0) {
      if (!wheelDisabled && !zoomDisabled) {
        zoomAtGlobal(e.deltaY > 0 ? 0.8 : 1.2, {
          x: e.pageX,
          y: e.pageY
        })
      } else {
        onWheel?.({ delta: e.deltaY, x: e.pageX, y: e.pageY })
      }
    }
  }

  useSpring({
    zoom: targetScale,
    translationX: targetTranslation.x,
    translationY: targetTranslation.y,
    immediate: !animate,
    config: { duration: 250 },
    onProps: () => {
      invalidate?.()
    },
    onChange: (result) => {
      setScale(result.value.zoom)
      setTranslation({
        x: result.value.translationX,
        y: result.value.translationY
      })
      invalidate?.()
    },
    onRest: (result) => {
      setScale(result.value.zoom)
      setTranslation({
        x: result.value.translationX,
        y: result.value.translationY
      })
    }
  })

  const bind = useGesture(
    {
      onMove: (state) => {
        onMouseMove?.(state)
      },
      onHover: (state) => {
        onHover?.(state)
      },
      onDragStart: (state) => {
        state.event.preventDefault()
        onDragStart?.(state)
        if (state.pinching) {
          state.cancel()
          setPanning(false)
        } else if (!panDisabled) {
          setPanning(true)
          startTranslation.current = translation
          onPanningStart?.()
        }
      },
      onDragEnd: (state) => {
        onDragEnd?.(state)
        if (state.pinching) {
          state.cancel()
        } else if (!panDisabled) {
          onPanningEnd?.()
        }
        setPanning(false)
      },
      onDrag: (state) => {
        state.event.preventDefault()
        onDrag?.(state)
        if (state.pinching) {
          state.cancel()
        } else {
          if (state.tap) {
            onClick?.({ x: state.xy[0], y: state.xy[1] })
            if (zoomOnClick && !zoomDisabled) {
              zoomAtGlobal(1.25, { x: state.xy[0], y: state.xy[1] })
              setPanning(false)
            }
          } else {
            if (!panDisabled) {
              let inc = {
                x: state.movement[0] / scale,
                y: state.movement[1] / scale
              }
              setTargetTranslation(addVectors(startTranslation.current, inc))
            }
          }
        }
      },

      onPinch: ({ pinching, da: [d], origin, first }) => {
        if (pinching && !panDisabled && !panning) {
          if (first) {
            lastPinchDistanceRef.current = d
          } else {
            zoomAtCenter(d < lastPinchDistanceRef.current ? 0.75 : 1.25, {
              x: origin[0],
              y: origin[1]
            })
            lastPinchDistanceRef.current = d
          }
        }
      }
    },
    {
      eventOptions: { passive: false },
      drag: { filterTaps: true }
    }
  )

  return (
    <Root
      ref={mergeRefs([rootBoundsRef, ref])}
      {...bind()}
      onWheel={handleWheel}
      cursor={getCursor(panning, panDisabled, cursor)}
      {...props}
    >
      {children}
      {tooltipsMargins && (
        <TooltipBoundaries
          ref={boundariesRef}
          tooltipsMargins={tooltipsMargins}
        />
      )}
    </Root>
  )
}

export default PanZoom
