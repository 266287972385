const FiltroIcon = ({
  primaryColor = '#000000',
  secondaryColor = '#220',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" {...props}>
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 4,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1
        }}
        d="M28.861 46.606a8 8 0 0 1-6.928-4 8 8 0 0 1 0-8 8 8 0 0 1 6.928-4M101.221 30.603a8 8.1 0 0 1 8 8.1 8 8.1 0 0 1-8 8.1"
      />
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 4,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1
        }}
        d="m28.861 46.606 72.36.197"
      />
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 4.00857,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1
        }}
        d="M28.902 30.578h72.057"
      />
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 4,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1
        }}
        d="M28.592 96.827a8 8 0 0 1-6.928-4 8 8 0 0 1 0-8 8 8 0 0 1 6.928-4M100.952 80.824a8 8.1 0 0 1 8 8.1 8 8.1 0 0 1-8 8.1"
      />
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 4,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1
        }}
        d="m28.592 96.827 72.36.197"
      />
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 4.00857,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1
        }}
        d="M28.633 80.798h72.057"
      />
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 4,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1
        }}
        d="M28.592 71.952a8 8 0 0 1-6.928-4 8 8 0 0 1 0-8 8 8 0 0 1 6.928-4M100.952 55.949a8 8.1 0 0 1 8 8.1 8 8.1 0 0 1-8 8.1"
      />
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 4,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1
        }}
        d="m28.592 71.952 72.36.197"
      />
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 4.00857,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1
        }}
        d="M28.633 55.923h72.057"
      />
      <path
        style={{
          fill: secondaryColor,
          fillOpacity: 1,
          stroke: secondaryColor,
          strokeWidth: 0.49257,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1
        }}
        d="M28.72 94.713c-1.125-.056-1.766-.148-2.155-.308-2.06-.847-3.48-2.597-3.83-4.718-.494-2.986 1.639-6.029 4.667-6.66.359-.075 1.813-.137 3.232-.137h2.58v2.002c0 1.1-.037 3.788-.081 5.972l-.082 3.971-1.365-.02a104.66 104.66 0 0 1-2.966-.102zM28.72 69.838c-1.125-.056-1.766-.148-2.155-.308-2.06-.847-3.48-2.597-3.83-4.718-.494-2.986 1.639-6.029 4.667-6.66.463-.097 7.609-.136 24.66-.136h24.006v2c0 1.101-.037 3.789-.082 5.973l-.081 3.972-22.792-.022c-12.536-.011-23.513-.057-24.393-.1zM27.68 44.402c-1.556-.338-2.976-1.319-3.826-2.643-1.165-1.815-1.194-4.294-.072-6.207.41-.7 1.626-1.828 2.387-2.213 1.358-.688 1.333-.687 12.063-.689l9.883-.002-.002 5.511c0 3.03-.036 5.718-.078 5.972l-.076.462-9.743-.01c-7.85-.008-9.897-.043-10.536-.181z"
      />
    </svg>
  )
}

export default FiltroIcon
