import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { filterStore } from './filterStore'
import lightStore from './lightStore'
import mainStore from './mainStore'
import modelStore from './modelStore'
import promotionStore from './promotionStore'

export const useH3DStore = create(
  devtools(
    (set, get) => ({
      ...mainStore(set, get),
      ...promotionStore(set, get),
      ...modelStore(set, get),
      ...filterStore(set, get),
      ...lightStore(set, get)
    }),
    { name: 'Store' }
  )
)
export default useH3DStore
