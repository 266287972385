import { useRef, useEffect } from 'react'

import * as THREE from 'three'
import { invalidate, useThree } from '@react-three/fiber'

export const SceneEquirectangularBackground = ({
  src,
  visible = true,
  onLoad
}) => {
  const textureLoader = useRef(new THREE.TextureLoader())
  const scene = useThree((st) => st.scene)

  useEffect(() => {
    const load = async () => {
      if (visible) {
        try {
          const texture = await textureLoader.current.loadAsync(src)
          texture.mapping = THREE.EquirectangularReflectionMapping
          //No tengo muy claro cual hay que poner. Ahora funciona sin tocarlo
          // texture.encoding = THREE.LinearEncoding
          texture.encoding = THREE.sRGBEncoding
          texture.needsUpdate = true
          scene.background = texture
        } catch (error) {
          console.log(error, src)
          scene.background = null
        }
      } else {
        scene.background = null
      }
      onLoad?.()
      invalidate()
    }
    load()

    return () => {
      scene.background = null
      invalidate()
    }
  }, [scene, src, visible, onLoad])

  return null
}
