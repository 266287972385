import { styled } from '@mui/material/styles'
import { getCurrentHouse } from '../../modules/promotionStore'
import useH3DStore from '../../modules/useH3DStore'

const Root = styled('div')(({ theme }) => ({
  userSelect: 'none',
  fontSize: '0.8rem',
  overflow: 'auto',
  minHeight: 0,
  flex: '0 1 auto',
  padding: '8px 0',
  ...theme.scrollbar
}))

const Row = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mobile' && prop !== 'textAlign'
})(({ theme, mobile, textAlign }) => ({
  userSelect: 'none',
  fontSize: mobile ? 12 : 18,
  overflow: 'auto',
  minHeight: 0,
  flex: '0 1 auto',
  textAlign,
  ...theme.scrollbar
}))

export const Details = ({ textAlign, mobile }) => {
  const house = useH3DStore(getCurrentHouse)
  return (
    <Root>
      <Row mobile={mobile} textAlign={textAlign}>
        Planta {house.floor}, Puerta {house.letter}
      </Row>
      <Row mobile={mobile} textAlign={textAlign}>
        {house.area}m² construidos
      </Row>
      <Row mobile={mobile} textAlign={textAlign}>
        {house.rooms} Dormitorios, {house.baths} Baños
      </Row>
    </Root>
  )
}
export default Details
