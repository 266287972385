import { styled } from '@mui/material/styles'

import Checkbox from '@mui/material/Checkbox'
import { FormControlLabel } from '@mui/material'
// import CheckIcon from '@mui/icons-material/Check'

const Label = styled(FormControlLabel)(({ theme }) => ({
  marginRight: 0,
  '&:first-of-type': {
    marginLeft: 0
  },
  '& .MuiTypography-root': {
    fontSize: 20,
    fontWeight: 400,
    fontFamily: `NeinorNormal ,Roboto, Helvetica, Arial, sans-serif`
  }
}))
const Control = styled(Checkbox)(({ theme }) => ({
  paddingRight: 0,
  '& .MuiSvgIcon-root': { fontSize: 24 }
}))

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 0,
  width: 24,
  height: 24,
  backgroundColor: 'grey'
}))

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main
}))

export const RoomCheckbox = ({ label, checked, onCheck }) => {
  return (
    <Label
      label={label}
      labelPlacement="start"
      control={
        <Control
          icon={<BpIcon />}
          checkedIcon={<BpCheckedIcon>{/* <CheckIcon /> */}</BpCheckedIcon>}
          disableRipple
          checked={checked}
          onChange={onCheck}
        />
      }
    />
  )
}

export default RoomCheckbox
