import Main from './components/Main'
import { ResizeObserver as Polyfill } from '@juggle/resize-observer'

function App() {
  if (!window.ResizeObserver) {
    window.ResizeObserver = Polyfill
  }

  return <Main />
}

export default App
