import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'
import useMeasure from 'react-use-measure'
import { styled, ThemeProvider } from '@mui/material/styles'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'

import MainTheme from '../settings/Theme'
import {
  fullscreenSelector,
  mainModeSelector,
  MAIN_MODE,
  setFullscreenAction,
  setRootBoundsAction
} from '../modules/mainStore'
import useH3DStore from '../modules/useH3DStore'
import { modelsSelector } from '../modules/modelStore'

import Center from './Center/Center'
import TopBar from './TopBar/TopBar'
import {
  initPromotionsAction,
  promotionsSelector,
  setCurrentPromotionAction
} from '../modules/promotionStore'
import StartView from './StartView/StartView'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'height'
})(({ theme, height }) => ({
  width: '100vw',
  height: height,
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'start',
  alignItems: 'stretch',
  position: 'relative',
  overflow: 'hidden',
  fontFamily: `NeinorFina, Neinor ,Roboto, Helvetica, Arial, sans-serif`,
  backgroundColor: 'white'
}))

const Main = () => {
  const initPromotions = useH3DStore(initPromotionsAction)
  const promotions = useH3DStore(promotionsSelector)
  const models = useH3DStore(modelsSelector)
  const mainMode = useH3DStore(mainModeSelector)
  const [height, setHeight] = useState(window.innerHeight)
  const [rootBoundsRef, rootBounds] = useMeasure({ polyfill: ResizeObserver })
  const setRootBounds = useH3DStore(setRootBoundsAction)
  const setCurrentPromotion = useH3DStore(setCurrentPromotionAction)
  // const setCurrentHouse = useH3DStore(setCurrentHouseAction)
  // const currentPromotion = useH3DStore(getCurrentPromotion)
  const fullscreen = useH3DStore(fullscreenSelector)
  const setFullscreen = useH3DStore(setFullscreenAction)
  const fullScreenHandle = useFullScreenHandle()

  useEffect(() => {
    initPromotions()
    // loadModelCfg()
    // setCurrentPromotion(2805)
  }, [initPromotions])

  useEffect(() => {
    if (models?.length && promotions?.length) {
      setCurrentPromotion(promotions[0].id)
    }
  }, [models, promotions, setCurrentPromotion])

  // useEffect(() => {
  //   if (currentPromotion) {
  //     if (currentPromotion.data) {
  //       setCurrentHouse(currentPromotion.data.houses[0].id)
  //     }
  //   }
  // }, [currentPromotion, setCurrentHouse])

  useLayoutEffect(() => {
    const handleEvent = (e) => {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleEvent)
    return () => {
      window.removeEventListener('resize', handleEvent)
    }
  }, [])

  //Tamaño
  useLayoutEffect(() => {
    if (rootBounds) {
      setRootBounds(rootBounds)
    }
  }, [rootBounds, setRootBounds])

  useEffect(() => {
    if (fullscreen && !fullScreenHandle.active) {
      fullScreenHandle.enter()
    } else if (!fullscreen && fullScreenHandle.active) {
      fullScreenHandle.exit()
    }
  }, [fullscreen, fullScreenHandle])

  const handleFullscreenChange = useCallback(
    (full) => {
      setFullscreen(full)
    },
    [setFullscreen]
  )

  return (
    <ThemeProvider theme={MainTheme}>
      <FullScreen handle={fullScreenHandle} onChange={handleFullscreenChange}>
        <Root ref={rootBoundsRef} height={height}>
          {mainMode === MAIN_MODE.DISABLED ||
          mainMode === MAIN_MODE.INITIALIZED ||
          mainMode === MAIN_MODE.LOADING_MODEL ? (
            <StartView />
          ) : (
            <TopBar />
          )}
          <Center />
        </Root>
      </FullScreen>
    </ThemeProvider>
  )
}
export default Main
