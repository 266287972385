import { IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
const Root = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  color: theme.palette.secondary.main,
  padding: 0,
  width: 48,
  height: 48
}))

function IconCloseOutline(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        d="M368 368L144 144M368 144L144 368"
      />
    </svg>
  )
}

const CloseButton = ({ onClick, iconProps, ...props }) => {
  return (
    <Root onClick={onClick} disableRipple {...props}>
      <IconCloseOutline style={{ width: '100%', height: '100%' }} />
    </Root>
  )
}

export default CloseButton
