import { createSelector } from 'reselect'

import {
  CHILD_FIT_MODE,
  DEFAULT_SCALE,
  DEFAULT_SCALE_MAX,
  SCALE_MIN,
  DEFAULT_TRANSLATION_MAX,
  DEFAULT_TRANSLATION_MIN
} from '../panZoomConstants'
import {
  boxCenter,
  centerAtLocalPoint,
  clamp,
  getChildCenter,
  getChildTopLeft2,
  getCoverChildScale,
  getFitChildScale,
  globalPointToLocalPoint,
  localPointToGlobalPoint,
  vector,
  zoomChildAtGlobalPoint,
  getChildOffset as getChildOffsetAux
} from '../../../utils/utils'

const reset = {
  fitMode: CHILD_FIT_MODE.FREE,
  panDisabled: false,
  zoomDisabled: false,

  targetScale: DEFAULT_SCALE,
  targetTranslation: { x: 0, y: 0 }, //Desplazamiento sin escala

  scale: DEFAULT_SCALE,
  scaleMin: SCALE_MIN,
  scaleMax: DEFAULT_SCALE_MAX,
  translation: { x: 0, y: 0 }, //Desplazamiento sin escala
  translationXMin: DEFAULT_TRANSLATION_MIN,
  translationXMax: DEFAULT_TRANSLATION_MAX,
  translationYMin: DEFAULT_TRANSLATION_MIN,
  translationYMax: DEFAULT_TRANSLATION_MAX,
  bounds: null,
  childSize: { width: 0, height: 0 }
}

export const panZoomStore = (set, get, id) => ({
  panZoom: {
    ...reset,
    reset: () => {
      const fitMode = pz_fitModeSelector(get())
      if (fitMode === CHILD_FIT_MODE.COVER) {
        updateCover(
          set,
          get,
          pz_boundsSelector(get()),
          pz_childSizeSelector(get()),
          0,
          vector()
        )
      } else if (fitMode === CHILD_FIT_MODE.CONTAIN) {
        updateContain(
          set,
          get,
          pz_boundsSelector(get()),
          pz_childSizeSelector(get()),
          0,
          vector()
        )
      } else if (fitMode === CHILD_FIT_MODE.INSIDE) {
        updateInside(
          set,
          get,
          pz_boundsSelector(get()),
          pz_childSizeSelector(get()),
          DEFAULT_SCALE_MAX,
          vector()
        )
      } else {
        const bounds = pz_boundsSelector(get())
        updateFree(
          set,
          get,
          getFitChildScale(pz_childSizeSelector(get()), {
            width: bounds ? bounds.width : 1,
            height: bounds ? bounds.height : 1
          }),
          vector()
        )
      }
    },
    setTargetScale: (targetScale) => {
      if (!pz_zoomDisabledSelector(get())) {
        updateTargetScale(set, get, targetScale)
      }
    },
    fit: () => {
      const st = get()
      const scale = getFitChildScale(
        pz_childSizeSelector(st),
        pz_boundsSelector(st)
      )
      pz_setTargetScaleAction(st)(scale)
    },
    setTargetTranslation: (targetTranslation) => {
      if (!pz_panDisabledSelector(get())) {
        updateTargetTranslation(set, get, targetTranslation)
      }
    },
    setPanDisabled: (disabled) => {
      set((prev) => ({ panZoom: { ...prev.panZoom, panDisabled: disabled } }))
    },
    setZoomDisabled: (disabled) => {
      set((prev) => ({
        panZoom: { ...prev.panZoom, zoomDisabled: disabled }
      }))
    },
    setChildFitMode: (fitMode) => {
      if (fitMode === CHILD_FIT_MODE.COVER) {
        set((prev) => ({
          panZoom: { ...prev.panZoom, fitMode, scaleMax: DEFAULT_SCALE_MAX }
        }))
        updateCover(
          set,
          get,
          pz_boundsSelector(get()),
          pz_childSizeSelector(get()),
          pz_scaleSelector(get()),
          pz_translationSelector(get())
        )
      } else if (fitMode === CHILD_FIT_MODE.CONTAIN) {
        set((prev) => ({
          panZoom: { ...prev.panZoom, fitMode, scaleMax: DEFAULT_SCALE_MAX }
        }))
        updateContain(
          set,
          get,
          pz_boundsSelector(get()),
          pz_childSizeSelector(get()),
          pz_scaleSelector(get()),
          pz_translationSelector(get())
        )
        set((prev) => ({ panZoom: { ...prev.panZoom, fitMode } }))
      } else if (fitMode === CHILD_FIT_MODE.INSIDE) {
        set((prev) => ({
          panZoom: { ...prev.panZoom, fitMode, scaleMin: SCALE_MIN }
        }))
        updateInside(
          set,
          get,
          pz_boundsSelector(get()),
          pz_childSizeSelector(get()),
          pz_scaleSelector(get()),
          pz_translationSelector(get())
        )
      } else if (fitMode === CHILD_FIT_MODE.FREE) {
        set((prev) => ({
          panZoom: {
            ...prev.panZoom,
            fitMode,
            scaleMin: SCALE_MIN,
            scaleMax: DEFAULT_SCALE_MAX,
            translationXMin: DEFAULT_TRANSLATION_MIN,
            translationXMax: DEFAULT_TRANSLATION_MAX,
            translationYMin: DEFAULT_TRANSLATION_MIN,
            translationYMax: DEFAULT_TRANSLATION_MAX
          }
        }))
        updateFree(
          set,
          get,
          pz_scaleSelector(get()),
          pz_translationSelector(get())
        )
      }
    },
    setScaleMin: (value) => {
      if (pz_fitModeSelector(get()) === CHILD_FIT_MODE.FREE) {
        const scaleMax = pz_scaleMaxSelector(get())
        const scaleMin = clamp(value, SCALE_MIN, scaleMax)
        set((prev) => ({ panZoom: { ...prev.panZoom, scaleMin } }))
        updateTargetScale(set, get, pz_scaleSelector(get()))
      }
    },
    setScaleMax: (value) => {
      if (pz_fitModeSelector(get()) === CHILD_FIT_MODE.FREE) {
        const scaleMin = pz_scaleMinSelector(get())
        const scaleMax = clamp(value, scaleMin, Number.MAX_VALUE)
        set((prev) => ({ panZoom: { ...prev.panZoom, scaleMax } }))
        updateTargetScale(set, get, pz_scaleSelector(get()))
      }
    },
    setTranslationXMin: (value) => {
      if (pz_fitModeSelector(get()) === CHILD_FIT_MODE.FREE) {
        const translationXMax = pz_translationXMaxSelector(get())
        const translationXMin = Math.min(value, translationXMax)
        set((prev) => ({ panZoom: { ...prev.panZoom, translationXMin } }))
        updateTargetTranslation(set, get, pz_translationSelector(get()))
      }
    },
    setTranslationXMax: (value) => {
      if (pz_fitModeSelector(get()) === CHILD_FIT_MODE.FREE) {
        const translationXMin = pz_translationXMinSelector(get())
        const translationXMax = Math.max(value, translationXMin)
        set((prev) => ({ panZoom: { ...prev.panZoom, translationXMax } }))
        updateTargetTranslation(set, get, pz_translationSelector(get()))
      }
    },
    setTranslationYMin: (value) => {
      if (pz_fitModeSelector(get()) === CHILD_FIT_MODE.FREE) {
        const translationYMax = pz_translationYMaxSelector(get())
        const translationYMin = Math.min(value, translationYMax)
        set((prev) => ({ panZoom: { ...prev.panZoom, translationYMin } }))
        updateTargetTranslation(set, get, pz_translationSelector(get()))
      }
    },
    setTranslationYMax: (value) => {
      if (pz_fitModeSelector(get()) === CHILD_FIT_MODE.FREE) {
        const translationYMin = pz_translationYMinSelector(get())
        const translationYMax = Math.max(value, translationYMin)
        set((prev) => ({ panZoom: { ...prev.panZoom, translationYMax } }))
        updateTargetTranslation(set, get, pz_translationSelector(get()))
      }
    },
    setScale: (scale) => {
      set((prev) => ({ panZoom: { ...prev.panZoom, scale } }))
    },
    setTranslation: (translation) => {
      set((prev) => ({ panZoom: { ...prev.panZoom, translation } }))
    },
    zoomAtGlobalPoint: (factor, point) => {
      if (!pz_zoomDisabledSelector(get())) {
        const currentTranslation = denormalizeTranslation(
          pz_translationSelector(get()),
          pz_scaleSelector(get())
        )

        const { scale, translation } = zoomChildAtGlobalPoint(
          pz_scaleSelector(get()),
          [pz_scaleMinSelector(get()), pz_scaleMaxSelector(get())],
          currentTranslation,
          factor,
          point,
          pz_boundsSelector(get())
        )

        const normTranslation = normalizeTranslation(translation, scale)
        const fitMode = pz_fitModeSelector(get())

        if (fitMode === CHILD_FIT_MODE.COVER) {
          updateCover(
            set,
            get,
            pz_boundsSelector(get()),
            pz_childSizeSelector(get()),
            scale,
            normTranslation
          )
        } else if (fitMode === CHILD_FIT_MODE.CONTAIN) {
          updateContain(
            set,
            get,
            pz_boundsSelector(get()),
            pz_childSizeSelector(get()),
            scale,
            normTranslation
          )
        } else if (fitMode === CHILD_FIT_MODE.INSIDE) {
          updateInside(
            set,
            get,
            pz_boundsSelector(get()),
            pz_childSizeSelector(get()),
            scale,
            normTranslation
          )
        } else {
          updateFree(set, get, scale, normTranslation)
        }
      }
    },
    zoomAtCenter: (factor) => {
      if (!pz_zoomDisabledSelector(get())) {
        const bounds = pz_boundsSelector(get())
        bounds &&
          pz_zoomAtGlobalPointAction(get())(
            factor,
            boxCenter(bounds.top, bounds.left, bounds.width, bounds.height)
          )
      }
    },

    setBounds: (bounds) => {
      const fitMode = pz_fitModeSelector(get())
      if (fitMode === CHILD_FIT_MODE.COVER) {
        updateCover(
          set,
          get,
          bounds,
          pz_childSizeSelector(get()),
          pz_scaleSelector(get()),
          pz_translationSelector(get())
        )
      } else if (fitMode === CHILD_FIT_MODE.CONTAIN) {
        updateContain(
          set,
          get,
          bounds,
          pz_childSizeSelector(get()),
          pz_scaleSelector(get()),
          pz_translationSelector(get())
        )
      } else if (fitMode === CHILD_FIT_MODE.INSIDE) {
        updateInside(
          set,
          get,
          bounds,
          pz_childSizeSelector(get()),
          pz_scaleSelector(get()),
          pz_translationSelector(get())
        )
      } else {
        set((prev) => ({ panZoom: { ...prev.panZoom, bounds } }))
      }
    },
    setChildSize: (size) => {
      const childSize = pz_childSizeSelector(get())
      if (size.width !== childSize.width || size.height !== childSize.height) {
        const fitMode = pz_fitModeSelector(get())
        if (fitMode === CHILD_FIT_MODE.COVER) {
          updateCover(
            set,
            get,
            pz_boundsSelector(get()),
            size,
            pz_scaleSelector(get()),
            pz_translationSelector(get())
          )
        } else if (fitMode === CHILD_FIT_MODE.CONTAIN) {
          updateContain(
            set,
            get,
            pz_boundsSelector(get()),
            size,
            pz_scaleSelector(get()),
            pz_translationSelector(get())
          )
        } else if (fitMode === CHILD_FIT_MODE.INSIDE) {
          updateInside(
            set,
            get,
            pz_boundsSelector(get()),
            size,
            pz_scaleSelector(get()),
            pz_translationSelector(get())
          )
        } else {
          set((prev) => ({ panZoom: { ...prev.panZoom, childSize: size } }))
        }
      }
    },
    centerAtPoint: (point) => {
      if (!pz_panDisabledSelector(get())) {
        //No esta adaptado a cover
        const currentTranslation = denormalizeTranslation(
          pz_translationSelector(get()),
          pz_scaleSelector(get())
        )
        const translation = centerAtLocalPoint(
          point,
          currentTranslation,
          pz_scaleSelector(get()),
          pz_childSizeSelector(get()),
          pz_boundsSelector(get())
        )
        updateTargetTranslation(
          set,
          get,
          normalizeTranslation(translation, pz_scaleSelector(get()))
        )
      }
    }
  }
})

export default panZoomStore

const updateTargetTranslation = (set, get, newTranslation) => {
  newTranslation = truncateTranslation(
    newTranslation,
    [pz_translationXMinSelector(get()), pz_translationXMaxSelector(get())],
    [pz_translationYMinSelector(get()), pz_translationYMaxSelector(get())]
  )
  const currentTranslation = pz_translationSelector(get())
  if (
    newTranslation.x !== currentTranslation.x ||
    newTranslation.y !== currentTranslation.y
  ) {
    set((prev) => ({
      panZoom: { ...prev.panZoom, targetTranslation: newTranslation }
    }))
  }
}
const updateTargetScale = (set, get, newScale) => {
  const targetScale = clamp(
    newScale,
    pz_scaleMinSelector(get()),
    pz_scaleMaxSelector(get())
  )
  set((prev) => ({ panZoom: { ...prev.panZoom, targetScale } }))
}

const denormalizeTranslation = (translation, scale) => {
  return {
    x: translation.x * scale,
    y: translation.y * scale
  }
}

const normalizeTranslation = (translation, scale) => {
  return {
    x: translation.x / scale,
    y: translation.y / scale
  }
}

const truncateTranslation = (translation, rangeX, rangeY) => {
  const x = clamp(translation.x, rangeX[0], rangeX[1])
  const y = clamp(translation.y, rangeY[0], rangeY[1])
  return { x, y }
}

const translationCoverLimits = (bounds, childSize, scale) => {
  let x = 0
  let y = 0
  if (
    bounds &&
    childSize &&
    bounds.width > 0 &&
    bounds.height > 0 &&
    childSize.width > 0 &&
    childSize.height > 0
  ) {
    x = (scale * childSize.width - bounds.width) / (2 * scale)
    y = (scale * childSize.height - bounds.height) / (2 * scale)
  }
  return {
    xMin: -x,
    xMax: x,
    yMin: -y,
    yMax: y
  }
}

const updateFree = (set, get, newScale, newTranslation) => {
  updateTargetTranslation(set, get, newTranslation)
  updateTargetScale(set, get, newScale)
}

const updateCover = (
  set,
  get,
  newBounds,
  newChildSize,
  newScale,
  newTranslation
) => {
  let scaleMin = getCoverChildScale(newChildSize, {
    width: newBounds ? newBounds.width : 0,
    height: newBounds ? newBounds.height : 0
  })

  newScale = clamp(newScale, scaleMin, pz_scaleMaxSelector(get()))
  const { xMin, xMax, yMin, yMax } = translationCoverLimits(
    newBounds,
    newChildSize,
    newScale
  )

  set((prev) => ({
    panZoom: {
      ...prev.panZoom,
      bounds: newBounds,
      childSize: newChildSize,
      scaleMin: scaleMin,
      translationXMin: xMin,
      translationXMax: xMax,
      translationYMin: yMin,
      translationYMax: yMax
    }
  }))
  updateTargetTranslation(set, get, newTranslation)
  updateTargetScale(set, get, newScale)
}

const translationContainLimits = (bounds, childSize, scale) => {
  let x = 0
  let y = 0
  if (bounds && childSize) {
    x = (scale * childSize.width - bounds.width) / (2 * scale)
    y = (scale * childSize.height - bounds.height) / (2 * scale)
  }

  return {
    xMin: x > 0 ? -x : x,
    xMax: x > 0 ? x : -x,
    yMin: y > 0 ? -y : y,
    yMax: y > 0 ? y : -y
  }
}

const updateContain = (
  set,
  get,
  newBounds,
  newChildSize,
  newScale,
  newTranslation
) => {
  let scaleMin = getFitChildScale(newChildSize, {
    width: newBounds ? newBounds.width : 0,
    height: newBounds ? newBounds.height : 0
  })

  newScale = clamp(newScale, scaleMin, pz_scaleMaxSelector(get()))
  const { xMin, xMax, yMin, yMax } = translationContainLimits(
    newBounds,
    newChildSize,
    newScale
  )

  set((prev) => ({
    panZoom: {
      ...prev.panZoom,
      bounds: newBounds,
      childSize: newChildSize,
      scaleMin: scaleMin,
      translationXMin: xMin,
      translationXMax: xMax,
      translationYMin: yMin,
      translationYMax: yMax
    }
  }))
  updateTargetTranslation(set, get, newTranslation)
  updateTargetScale(set, get, newScale)
}

const translationInsideLimits = (bounds, childSize, scale) => {
  let x = 0
  let y = 0
  if (bounds && childSize) {
    x = (scale * childSize.width - bounds.width) / (2 * scale)
    y = (scale * childSize.height - bounds.height) / (2 * scale)
  }

  return {
    xMin: x > 0 ? -x : x,
    xMax: x > 0 ? x : -x,
    yMin: y > 0 ? -y : y,
    yMax: y > 0 ? y : -y
  }
}

const updateInside = (
  set,
  get,
  newBounds,
  newChildSize,
  newScale,
  newTranslation
) => {
  let scaleMax = getFitChildScale(newChildSize, {
    width: newBounds ? newBounds.width : 0,
    height: newBounds ? newBounds.height : 0
  })

  newScale = clamp(newScale, pz_scaleMinSelector(get()), scaleMax)
  const { xMin, xMax, yMin, yMax } = translationInsideLimits(
    newBounds,
    newChildSize,
    newScale
  )
  set((prev) => ({
    panZoom: {
      ...prev.panZoom,
      bounds: newBounds,
      childSize: newChildSize,
      scaleMax,
      translationXMin: xMin,
      translationXMax: xMax,
      translationYMin: yMin,
      translationYMax: yMax
    }
  }))
  updateTargetTranslation(set, get, newTranslation)
  updateTargetScale(set, get, newScale)
}

export const pz_setChildFitModeAction = (state) => state.panZoom.setChildFitMode
export const pz_setPanDisabledAction = (state) => state.panZoom.setPanDisabled
export const pz_setZoomDisabledAction = (state) => state.panZoom.setZoomDisabled
export const pz_setTargetScaleAction = (state) => state.panZoom.setTargetScale
export const pz_fitAction = (state) => state.panZoom.fit
export const pz_setTargetTranslationAction = (state) =>
  state.panZoom.setTargetTranslation
export const pz_setScaleAction = (state) => state.panZoom.setScale
export const pz_setTranslationAction = (state) => state.panZoom.setTranslation

export const pz_setScaleMinAction = (state) => state.panZoom.setScaleMin
export const pz_setScaleMaxAction = (state) => state.panZoom.setScaleMax
export const pz_setTranslationXMinAction = (state) =>
  state.panZoom.setTranslationXMin
export const pz_setTranslationXMaxAction = (state) =>
  state.panZoom.setTranslationXMax
export const pz_setTranslationYMinAction = (state) =>
  state.panZoom.setTranslationYMin
export const pz_setTranslationYMaxAction = (state) =>
  state.panZoom.setTranslationYMax

export const pz_resetPanZoomAction = (state) => state.panZoom.reset

export const pz_zoomAtCenterAction = (state) => state.panZoom.zoomAtCenter
export const pz_zoomAtGlobalPointAction = (state) =>
  state.panZoom.zoomAtGlobalPoint
export const pz_setBoundsAction = (state) => state.panZoom.setBounds
export const pz_setChildSizeAction = (state) => state.panZoom.setChildSize
export const pz_centerAtPointAction = (state) => state.panZoom.centerAtPoint

export const pz_fitModeSelector = (state) => state.panZoom.fitMode
export const pz_panDisabledSelector = (state) => state.panZoom.panDisabled
export const pz_zoomDisabledSelector = (state) => state.panZoom.zoomDisabled

export const pz_scaleSelector = (state) => state.panZoom.scale
export const pz_translationSelector = (state) => state.panZoom.translation
export const pz_targetScaleSelector = (state) => state.panZoom.targetScale
export const pz_targetTranslationSelector = (state) =>
  state.panZoom.targetTranslation

export const pz_scaleMinSelector = (state) => state.panZoom.scaleMin
export const pz_scaleMaxSelector = (state) => state.panZoom.scaleMax
export const pz_translationXMinSelector = (state) =>
  state.panZoom.translationXMin
export const pz_translationXMaxSelector = (state) =>
  state.panZoom.translationXMax
export const pz_translationYMinSelector = (state) =>
  state.panZoom.translationYMin
export const pz_translationYMaxSelector = (state) =>
  state.panZoom.translationYMax
export const pz_boundsSelector = (state) => state.panZoom.bounds
export const pz_childSizeSelector = (state) => state.panZoom.childSize

export const pz_getScaleRange = (state) => {
  return [pz_scaleMinSelector(state), pz_scaleMaxSelector(state)]
}

//Desplazamiento con la escala aplicada
export const pz_getTranslation = createSelector(
  [pz_translationSelector, pz_scaleSelector],
  (translation, scale) => {
    return denormalizeTranslation(translation, scale)
  }
)

//Centro del container
export const pz_getGlobalCenter = createSelector(
  [pz_boundsSelector],
  (bounds) => {
    if (!bounds) {
      return { x: 0, y: 0 }
    }
    return boxCenter(bounds.top, bounds.left, bounds.width, bounds.height)
  }
)

//Centro del child
export const pz_getGlobalChildCenter = createSelector(
  [pz_boundsSelector, pz_getTranslation],
  (bounds, translation) => {
    return getChildCenter(bounds, translation)
  }
)

//Desplazamiento del child para que este centrada en el canvas.
export const pz_getChildOffset = createSelector(
  [
    pz_boundsSelector,
    pz_scaleSelector,
    pz_getTranslation,
    pz_childSizeSelector
  ],
  (bounds, scale, translation, childSize) => {
    return getChildOffsetAux(scale, translation, childSize, bounds)
  }
)

//Punto topLeft del child
export const pz_getGlobalChildTopLeft = createSelector(
  [pz_getChildOffset, pz_boundsSelector],
  (offset, bounds) => {
    return getChildTopLeft2(offset, bounds)
  }
)

//Punto de child en coordenadas globales
export const childPointToGlobalPoint = createSelector(
  [pz_getChildOffset, pz_scaleSelector],
  (offset, scale) => (x, y) => {
    return localPointToGlobalPoint({ x, y }, scale, offset)
  }
)

//Punto global a coordenadas child
export const globalPointToChildPoint = (state) => (x, y) => {
  return globalPointToLocalPoint(
    { x, y },
    pz_scaleSelector(state),
    pz_getGlobalChildTopLeft(state)
  )
}
