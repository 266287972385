import { useCallback, useEffect } from 'react'
import { styled, alpha } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import CloseButton from '../Common/CloseButton'

// import Cell from './Cell'

import {
  boundsSelector,
  lightActiveSelector,
  setLightActiveAction
} from '../../modules/mainStore'
import useH3DStore from '../../modules/useH3DStore'
import {
  currentHouseSelector,
  getCurrentPromotion
} from '../../modules/promotionStore'
import FilterSlider from '../Common/FilterSlider'
import { Slider } from '@mui/material'
import {
  lightAngleSelector,
  setLightAngleAction,
  toggleLightAnimationAction
} from '../../modules/lightStore'

const Root = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'narrow'
})(({ theme, narrow }) => ({
  fontFamily: `NeinorNormal ,Roboto, Helvetica, Arial, sans-serif`,
  position: 'absolute',
  zIndex: theme.zIndex.overlay,
  bottom: 12,
  left: '50%',
  transform: 'translate(-50%,0)',
  boxSizing: 'border-box',
  padding: 4,
  width: 800,
  maxWidth: '96%',
  height: narrow ? 200 : 120,
  display: 'flex',
  flexFlow: narrow ? 'column' : 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.primary.light, 0.9),
  borderRadius: 0
}))

const SSlider = styled(Slider)(({ theme }) => ({
  margin: 0,
  boxSizing: 'border-box',
  padding: '0 0px',
  width: 'calc(100% - 12px)',

  '& .MuiSlider-thumb': {
    width: 16,
    height: 16,
    '&:hover': {
      boxShadow: 'none'
    }
  },
  '& .MuiSlider-track': {
    backgroundColor: 'grey'
  },
  '& .MuiSlider-rail': {
    backgroundColor: 'grey'
  }
}))

// const Price = ({ sliderPriceRange, filterPriceRange, narrow, onChange }) => {
//   //Ajusta priceRange al principio
//   useEffect(() => {
//     if (
//       filterPriceRange[0] < sliderPriceRange[0] ||
//       filterPriceRange[1] > sliderPriceRange[1]
//     ) {
//       onChange?.(sliderPriceRange[0], sliderPriceRange[1])
//     }
//   }, [sliderPriceRange, filterPriceRange, onChange])

//   const handleChange = useCallback(
//     (e, newValue) => {
//       onChange?.(newValue[0], newValue[1])
//     },
//     [onChange]
//   )

//   return (
//     <Cell title={'Precio'} vertical={narrow}>
//       <FilterSlider
//         min={sliderPriceRange[0]}
//         max={sliderPriceRange[1]}
//         range={filterPriceRange}
//         step={1000}
//         unit={'€'}
//         onRangeChange={handleChange}
//       />
//     </Cell>
//   )
// }

// const Rooms = ({
//   options, //[{id,nRooms}]
//   selected, //[id,...]
//   narrow,
//   onChange
// }) => {
//   return (
//     <Cell title={'Dormitorios'} vertical={narrow}>
//       <div
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'space-around',
//           boxSizing: 'border-box',
//           width: '100%',
//           height: '100%',
//           padding: '0 24px'
//         }}
//       >
//         {options.map((roomOption) => (
//           <RoomCheckbox
//             key={roomOption.id}
//             label={roomOption.nRooms}
//             checked={selected.includes(roomOption.id)}
//             onCheck={() => onChange?.(roomOption.id)}
//           />
//         ))}
//       </div>
//     </Cell>
//   )
// }

export const Light = () => {
  const promotion = useH3DStore(getCurrentPromotion)
  const currentHouse = useH3DStore(currentHouseSelector)
  const lightActive = useH3DStore(lightActiveSelector)
  const setLightActive = useH3DStore(setLightActiveAction)
  const bounds = useH3DStore(boundsSelector)
  const narrow = bounds?.width > 0 && bounds?.width < 720

  const handleFilter = useCallback(() => {
    setLightActive(false)
  }, [setLightActive])

  const lightAngle = useH3DStore(lightAngleSelector)
  const setLightAngle = useH3DStore(setLightAngleAction)
  const toggleLightAnimation = useH3DStore(toggleLightAnimationAction)
  const handleLight = useCallback(
    (e) => {
      setLightAngle(e.target.value)
    },
    [setLightAngle]
  )
  const handleAnimate = useCallback(() => {
    toggleLightAnimation()
  }, [toggleLightAnimation])

  return promotion && !currentHouse && lightActive ? (
    <Root elevation={4} narrow={narrow}>
      <CloseButton onClick={handleFilter} />
      <SSlider
        color="secondary"
        size="small"
        min={0}
        max={Math.PI}
        step={0.1}
        value={lightAngle}
        onChange={handleLight}
      />
      <button onClick={handleAnimate}>Animar/Parar</button>
    </Root>
  ) : null
}
export default Light
