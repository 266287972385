import { styled } from '@mui/material/styles'
import { formatNumber } from '../../utils/utils'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mobile' && prop !== 'textAlign'
})(({ theme, mobile, textAlign }) => ({
  flex: '0 0 auto',
  userSelect: 'none',
  fontSize: mobile ? 16 : 28,
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: 8,
  textAlign,
  fontFamily: `ITCAvantGardeStd-Md, Neinor ,Roboto, Helvetica, Arial, sans-serif`,
  fontWeight: 400
}))

export const Price = ({ price, textAlign, mobile, venta }) => {
  return venta ? (
    <Root textAlign={textAlign} mobile={mobile}>
      {formatNumber(price)}€
    </Root>
  ) : (
    <Root textAlign={textAlign} mobile={mobile}>
      {formatNumber(price)}€ / Mes
    </Root>
  )
}
export default Price
