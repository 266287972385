import { createSelector } from 'reselect'
import {
  mainModeSelector,
  MAIN_MODE,
  setBackgroundActiveAction,
  setMainModeAction,
  setProgressAction
} from './mainStore'
import { setCurrentModelAction, setModelsAction } from './modelStore'
import { findById } from '../utils/utils'

export const HOUSE_STATE_COLORS = {
  0: {
    color: { r: 1, g: 0, b: 0 },
    name: 'Gestión de alquiler',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  1: {
    color: { r: 0, g: 1, b: 0 },
    name: 'Libre',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  2: {
    color: { r: 1, g: 0, b: 0 },
    name: 'En gestión',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  5: {
    color: { r: 0, g: 1, b: 0 },
    name: 'Alquiler',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  8: {
    color: { r: 0, g: 1, b: 0 },
    name: 'Ofertado',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  10: {
    color: { r: 1, g: 0, b: 0 },
    name: 'Reservada',
    stateBillboard: true,
    showBillboard: true,
    showPrice: false,
    showPlans: false,
    showBook: false
  },
  15: {
    color: { r: 0, g: 1, b: 0 },
    name: 'Reserva Alquiler',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  20: {
    color: { r: 1, g: 0, b: 0 },
    name: 'Vendida',
    stateBillboard: true,
    showBillboard: true,
    showPrice: false,
    showPlans: false,
    showBook: false
  },
  21: {
    color: { r: 1, g: 0, b: 0 },
    name: 'Vendida/Liquidada',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  25: {
    color: { r: 1, g: 0, b: 0 },
    name: 'Alquilada',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  30: {
    color: { r: 1, g: 0, b: 0 },
    name: 'Entregada',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  31: {
    color: { r: 1, g: 0, b: 0 },
    name: 'Baja por cesión',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  32: {
    color: { r: 1, g: 0, b: 0 },
    name: 'Baja por segregación',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  33: {
    color: { r: 1, g: 0, b: 0 },
    stateBillboard: false,
    name: 'Baja',
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  50: {
    color: { r: 0.3, g: 0.3, b: 0.3 },
    stateBillboard: false,
    name: 'No disponible',
    showBillboard: false,
    showPrice: false,
    showPlans: true,
    showBook: false
  },
  51: {
    color: { r: 0, g: 1, b: 0 },
    name: 'Alquiler por Estancias',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  },
  95: {
    color: { r: 1, g: 0, b: 0 },
    name: 'Nulo- Baja',
    stateBillboard: false,
    showBillboard: true,
    showPrice: true,
    showPlans: true,
    showBook: false
  }
}

export const promotionStore = (set, get) => ({
  promotion: {
    promotions: [], // {id,modelId,data}
    currentPromotion: '',
    currentHouse: '',
    init: async () => {
      if (mainModeSelector(get()) === MAIN_MODE.DISABLED) {
        setMainModeAction(get())(MAIN_MODE.LOADING_MODEL)
        const settingsFile = await readSettings('settings.json')
        const promotions = await readSettings(
          settingsFile.promotionSettingsFile
        )
        const validPromotions = []
        const models = []
        if (promotions) {
          for (const p of promotions) {
            const modelSettings = await readSettings(p.modelSettings)
            if (modelSettings) {
              validPromotions.push(p)
              models.push(modelSettings)
            }
          }
        }
        // const models = await readSettings('modelSettings.json')

        set((prev) => ({
          promotion: {
            ...prev.promotion,
            promotions: validPromotions,
            currentPromotion: '',
            currentHouse: ''
          }
        }))
        setModelsAction(get())(models)
        setMainModeAction(get())(MAIN_MODE.INITIALIZED)
        setProgressAction(get())(0)
      }
    },

    setCurrentPromotion: async (promotionId) => {
      if (currentPromotionSelector(get()) !== promotionId) {
        const promotion = getPromotionSimple(get())(promotionId)
        // console.log(promotion)
        if (promotion) {
          set((prev) => ({
            promotion: {
              ...prev.promotion,
              currentPromotion: promotionId,
              currentHouse: ''
            }
          }))
          setPromotionDataAction(get())(promotionId, null)
          setProgressAction(get())(10)
          setMainModeAction(get())(MAIN_MODE.LOADING_MODEL)
          setCurrentModelAction(get())(promotion.modelId)
          setBackgroundActiveAction(get())(promotion.backgroundActive)
        }
      }
    },
    setPromotionData: (promotionId, data) => {
      // console.log(promotionId, data)

      set((prev) => ({
        promotion: {
          ...prev.promotion,
          promotions: promotionsSelector(get()).map((p) => {
            if (p.id === promotionId) {
              return {
                ...p,
                data: formatPromotionData(p, data),
                loaded: data !== null
              }
            }
            return p
          })
        }
      }))
      get().filter.reset()
    },
    setCurrentHouse: (id) => {
      const promotion = getCurrentPromotion(get())
      const house = promotion ? findById(promotion.data.houses, id) : null
      set((prev) => ({
        promotion: { ...prev.promotion, currentHouse: house ? id : '' }
      }))
    },
    setCurrentHouseByNodeId: (nodeId) => {
      const promotion = getCurrentPromotion(get())
      const house = promotion
        ? promotion.data.houses.find((h) => h.nodeId === nodeId)
        : null

      set((prev) => ({
        promotion: { ...prev.promotion, currentHouse: house ? house.id : '' }
      }))
    },
    loadDefaultPromotion: () => {
      // const promotions = getPromotions(get())
      // if (promotions.length) {
      //   setCurrentPromotionAction(get())(promotions[1].id)
      // }
    },
    loadCurrentPromotionData: async () => {
      const promotion = getCurrentPromotion(get())
      if (promotion?.withPrinex) {
        if (!promotion.loaded) {
          const token = await getTokenPromise()
          if (token) {
            const data = await readPromotion(token, promotion)
            setPromotionDataAction(get())(promotion.id, data)
          }
        }
      }
      setMainModeAction(get())(MAIN_MODE.VIEW_3D)
    }
  }
})
export default promotionStore

const readSettings = async (src) => {
  try {
    const response = await fetch(src, {
      cache: 'no-cache'
    })
    if (response.ok) {
      return await response.json()
    }
  } catch (error) {
    return null
  }
}

const getTokenPromise = async () => {
  console.log('Token')
  try {
    const response = await fetch(
      `https://apps.prinex.com/cupe_backend/api/CUPE_BKND_WS_Admin/login_app/?jsondata={"parametros":{"usermail":"catalogo.neinor@prinex.es","password":"b0baee9d279d34fa1dfd71aadb908c3f","password_esta_encriptada":true,"cod_app":${10015}}}`,
      { cache: 'no-cache' }
    )
    if (response.ok) {
      const data = await response.json()
      return data.result.data.data[0].token_sesion
    } else {
      return ''
    }
  } catch (error) {
    console.log(error)
  }
}
const readPromotion = async (token, promotion) => {
  try {
    let totalData = null
    for (const id of promotion.promotionIds) {
      console.log('Read')

      const ruta = `https://apps.prinex.com/cupe_backend/api/CUPE_BKND_WS_Catalogo_Virtual/get_datos_catalogo/?jsondata={"parametros":{"id_agrupacion_bd":${promotion.id_agrupacion_bd},"id_bd_prinex":"${promotion.id_bd_prinex}","cod_promocion":${id},"cod_naturaleza_acceso":"${promotion.cod_naturaleza_acceso}","token_sesion":"${token}","cod_app":${promotion.cod_app},"id_actor":${promotion.id_actor}}}`
      const response = await fetch(ruta, { cache: 'no-cache' })
      console.log(ruta)
      if (response.ok) {
        const data = await response.json()
        console.log(data.result)
        console.log(data.result.data.data[0])
        if (!totalData) {
          totalData = {}
        }
        totalData[id] = data.result.data.data[0]
      }
    }
    return totalData
  } catch (error) {
    console.log(error)
    return null
  }
}

const formatPromotionData = (promotion, data) => {
  // console.log(promotion, data)
  if (promotion) {
    const promotionInitialData = promotion.initialData
    const apiHouses = data
      ? promotion.promotionIds.reduce((acc, id) => {
          if (data[id]) {
            acc = [...acc, ...data[id].inmuebles.data]
          }
          return acc
        }, [])
      : []
    // generateDataPanoramic(promotionInitialData.houses, apiHouses)
    const houses = promotionInitialData.houses.map((h) => {
      const apiHouse = apiHouses.find((apiHouse) => {
        return apiHouse.CODIGO_ACTIVO_INMUEBLE === h.cod
      })
      // console.log(apiHouse)
      return {
        ...h,
        cod: apiHouse?.CODIGO_ACTIVO_INMUEBLE
          ? apiHouse.CODIGO_ACTIVO_INMUEBLE
          : h.id,
        rooms: apiHouse?.NUM_DORMITORIOS
          ? parseInt(apiHouse.NUM_DORMITORIOS)
          : 0,
        baths: apiHouse?.NUM_BANIOS ? parseInt(apiHouse.NUM_BANIOS) : 0,
        description: apiHouse ? apiHouse.DESC_INMUEBLE_PRINCIPAL : '',
        area: apiHouse?.M2_CONSTRUIDOS
          ? parseFloat(apiHouse.M2_CONSTRUIDOS)
          : 0,
        rentState: apiHouse ? apiHouse.COD_ESTADO_INMUEBLE_ALQ : '',
        sellState: apiHouse ? apiHouse.COD_ESTADO_INMUEBLE : '',
        floor: apiHouse ? apiHouse.CLAVE_3.replace(/(^0)/gi, '') : '***',
        letter: apiHouse ? apiHouse.CLAVE_4 : '***',
        portal: apiHouse ? apiHouse.CLAVE_2 : '***',
        price: apiHouse?.IMPORTE ? parseFloat(apiHouse.IMPORTE) : 0,
        rentPrice: apiHouse?.IMPORTE_ALQUILER
          ? parseFloat(apiHouse.IMPORTE_ALQUILER)
          : 0
      }
    })

    const { rooms, baths } = houses.reduce(
      (acc, h) => {
        !acc.rooms.includes(h.rooms) && acc.rooms.push(h.rooms)
        !acc.baths.includes(h.baths) && acc.baths.push(h.baths)
        return acc
      },
      { rooms: [], baths: [] }
    )
    rooms.sort()
    baths.sort()
    return {
      localization: {
        lon: data ? data.LATITUD : 0,
        lat: data ? data.LONGITUD : 0
      },
      roomOptions: rooms.map((r, idx) => ({ id: `ro${idx}`, nRooms: r })),
      bathsOptions: baths.map((r, idx) => ({ id: `ro${idx}`, nBaths: r })),
      houses,
      priceRange: getRentPriceRange(houses),
      ventaPriceRange: getVentaPriceRange(houses)
    }
  }
}

const getRentPriceRange = (houses) => {
  const range = houses
    ? houses.reduce(
        (acc, house) => {
          if (house.rentPrice) {
            if (house.rentPrice > acc[1]) {
              acc[1] = house.rentPrice
            }
            if (house.rentPrice < acc[0]) {
              acc[0] = house.rentPrice
            }
          }
          return acc
        },
        houses.length > 0 && houses[0].rentPrice
          ? [houses[0].rentPrice, houses[0].rentPrice]
          : [0, 0]
      )
    : [0, 0]

  if (range[1] > 0) {
    return [Math.floor(range[0] / 100) * 100, Math.ceil(range[1] / 100) * 100]
  }
  return [0, 6000]
}
const getVentaPriceRange = (houses) => {
  const range = houses
    ? houses.reduce(
        (acc, house) => {
          if (house.price) {
            if (house.price > acc[1]) {
              acc[1] = house.price
            }
            if (house.price < acc[0]) {
              acc[0] = house.price
            }
          }
          return acc
        },
        houses.length > 0 && houses[0].price
          ? [houses[0].price, houses[0].price]
          : [0, 0]
      )
    : [0, 0]

  if (range[1] > 0) {
    return [Math.floor(range[0] / 100) * 100, Math.ceil(range[1] / 100) * 100]
  }
  return [0, 1000000]
}

//NO QUITAR
export const generateSkyTorre4HousesFromThreeNodes = (nodes) => {
  const numberLetter = { A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8 }
  console.log(
    JSON.stringify(
      Object.keys(nodes).map((id) => {
        const fields = id.split('_')
        return {
          id,
          nodeId: id,
          codPromocion: '1971',
          map: `models/neinor_sky/planosPortalD/CM-01-4${fields[3].padStart(
            2,
            '0'
          )}${numberLetter[fields[5]]} - PUERTA ${fields[5]}.jpg`
        }
      })
    )
  )
}
export const generateSotoHousesFromThreeNodes = (nodes) => {
  console.log(
    JSON.stringify(
      Object.keys(nodes).map((id) => {
        return {
          id,
          nodeId: id,
          codPromocion: '4375',
          map: `models/neinor_soto/plans/${id}.jpg`
        }
      })
    )
  )
}
export const generatePanoramicHousesFromThreeNodes = (nodes) => {
  console.log(nodes)
  console.log(
    JSON.stringify(
      Object.keys(nodes).map((id) => {
        const node = nodes[id]
        return {
          id: node.name,
          nodeId: node.name,
          codPromocion: '2288',
          map: `models/neinor_panoramic/plans/${node.name}.jpg`
        }
      })
    )
  )
}
const generateDataPanoramic = (houses, apiHouses) => {
  if (houses && apiHouses) {
    console.log(houses, apiHouses)
    const data = apiHouses.reduce((acc, apiHouse) => {
      const planta = apiHouse.CLAVE_3 === 'BAJ' ? '00' : apiHouse.CLAVE_3
      let nodeId = `Portal${apiHouse.CLAVE_2}-Planta${planta}-Puerta${apiHouse.CLAVE_4}`
      if (nodeId === 'Portal1-Planta14-PuertaD') {
        nodeId = 'Portal1-Planta14-PuertaD_'
      } else if (nodeId === 'Portal1-Planta00-PuertaA') {
        nodeId = 'Portal1-Planta00-Puerta_A'
      }
      const house = houses.find((h) => h.nodeId === nodeId)
      if (house) {
        acc.push({
          // id: house.id,
          id: apiHouse.ID_INMUEBLE,
          cod: apiHouse.CODIGO_ACTIVO_INMUEBLE,
          codPromocion: 2288,
          nodeId: house.nodeId,
          map: house.map
        })
      } else {
        console.log(nodeId)
      }
      return acc
    }, [])
    console.log(JSON.stringify(data))
  }
}
const generateDataSky = (houses, apiHouses) => {
  if (houses && apiHouses) {
    console.log(houses, apiHouses)
    const data = apiHouses.reduce((acc, apiHouse) => {
      const nodeId = `PORTAL_${
        apiHouse.CLAVE_1
      }_PLANTA_${apiHouse.CLAVE_2.replace(/(^0)/gi, '')}_PUERTA_${
        apiHouse.CLAVE_3
      }`
      const house = houses.find((h) => h.nodeId === nodeId)
      if (house) {
        acc.push({
          id: house.id,
          cod: apiHouse.CODIGO_ACTIVO_INMUEBLE,
          nodeId: house.nodeId,
          map: house.map
        })
      }
      return acc
    }, [])
    console.log(JSON.stringify(data))
  }
}
const generateDataSoto = (houses, apiHouses) => {
  if (houses && apiHouses) {
    console.log(houses, apiHouses)
    const data = apiHouses.reduce((acc, apiHouse) => {
      const nodeId = `PORTAL_${
        apiHouse.CLAVE_PORTAL
      }_PLANTA_${apiHouse.CLAVE_PLANTA.replace(/(^0)/gi, '')}_${
        apiHouse.CLAVE_PUERTA
      }`
      const house = houses.find((h) => h.nodeId === nodeId)
      if (house) {
        acc.push({
          id: house.id,
          cod: apiHouse.CODIGO_ACTIVO_INMUEBLE,
          nodeId: house.nodeId,
          map: house.map
        })
      }
      return acc
    }, [])
    console.log(JSON.stringify(data))
  }
}
//HASTA AQUI

export const initPromotionsAction = (state) => state.promotion.init
// export const setPromotionsAction = (state) => state.promotion.setPromotions

export const readingPromotionSelector = (state) =>
  state.promotion.readingPromotion
export const setCurrentPromotionAction = (state) =>
  state.promotion.setCurrentPromotion
export const setCurrentHouseAction = (state) => state.promotion.setCurrentHouse
export const setCurrentHouseByNodeIdAction = (state) =>
  state.promotion.setCurrentHouseByNodeId
export const loadDefaultPromotionAction = (state) =>
  state.promotion.loadDefaultPromotion
export const setPromotionDataAction = (state) =>
  state.promotion.setPromotionData
export const loadCurrentPromotionDataAction = (state) =>
  state.promotion.loadCurrentPromotionData

export const promotionsSelector = (state) => state.promotion.promotions
export const currentPromotionSelector = (state) =>
  state.promotion.currentPromotion
export const currentHouseSelector = (state) => state.promotion.currentHouse

export const getPromotionSimple = (state) => (id) => {
  return findById(promotionsSelector(state), id)
}

export const getCurrentPromotion = createSelector(
  [promotionsSelector, currentPromotionSelector],
  (promotions, id) => {
    return findById(promotions, id)
  }
)

export const getCurrentHouse = createSelector(
  [getCurrentPromotion, currentHouseSelector],
  (promotion, id) => {
    const house = promotion?.data ? findById(promotion.data.houses, id) : null
    if (house) {
      return {
        ...house,
        details: [
          `${house.area}² construidos`,
          `${house.rooms} Dormitorios, ${house.baths} Baños`
        ]
      }
    }
    return null
  }
)

export const getCurrentPromotionHousesNodes = createSelector(
  [getCurrentPromotion],
  (promotion) => {
    return promotion ? promotion.data.houses.map((h) => h.nodeId) : []
  }
)
