export const ZOOM_FACTOR = 0.1
export const DEFAULT_SCALE = 1
export const SCALE_MIN = 0.001
export const DEFAULT_SCALE_MAX = 1000
export const DEFAULT_TRANSLATION_MAX = Number.MAX_SAFE_INTEGER
export const DEFAULT_TRANSLATION_MIN = Number.MIN_SAFE_INTEGER

export const CHILD_FIT_MODE = {
  FREE: 'FREE',
  COVER: 'COVER',
  CONTAIN: 'CONTAIN',
  INSIDE: 'INSIDE'
}
