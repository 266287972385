import { styled } from '@mui/material/styles'
import { forwardRef } from 'react'

import { SCALE_MIN } from './panZoomConstants'

const Root = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'scale' && prop !== 'translation' && prop !== 'size'
})(({ theme, scale, translation, size }) => ({
  position: 'absolute',
  transformOrigin: 'center',
  transform: `scale(${scale}) translate(${translation.x}px, ${translation.y}px)`,
  width: size.width,
  height: size.height,
  overflow: 'hidden',
  touchAction: 'none',
  backgroundColor: 'blue'
}))

export const PanZoomContainer = forwardRef(
  (
    {
      scale = 1,
      translation = { x: 0, y: 0 },
      childSize = { width: 0, height: 0 },
      children,
      ...props
    },
    ref
  ) => {
    return (
      <Root
        ref={ref}
        scale={Math.max(SCALE_MIN, scale)}
        translation={translation}
        size={childSize}
        {...props}
      >
        {children}
      </Root>
    )
  }
)

export default PanZoomContainer
