import { styled, alpha, darken } from '@mui/material/styles'

const Root = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'mobile' && prop !== 'selected' && prop !== 'disabled'
})(({ theme, selected, disabled, mobile }) => ({
  width: mobile ? 64 : 100,
  height: mobile ? 64 : 100,
  padding: 8,
  paddingTop: 0,
  boxSizing: 'border-box',
  margin: mobile ? 8 : 12,
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: disabled
    ? alpha(darken(theme.palette.primary.main, 0.5), 0.5)
    : selected
    ? theme.palette.secondary.main
    : theme.palette.primary.main,
  position: 'relative',
  cursor: disabled ? null : 'pointer',
  '&:hover': {
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      backgroundColor: disabled
        ? null
        : alpha(darken(theme.palette.primary.main, 0.5), 0.5)
    }
  }
}))

// const Image = styled('img', {
//   shouldForwardProp: (prop) => prop !== 'mobile'
// })(({ theme, mobile }) => ({
//   width: mobile ? 40 : 68,
//   height: mobile ? 40 : 68
// }))
const Title = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mobile' && prop !== 'selected'
})(({ theme, mobile, selected }) => ({
  fontFamily: `NeinorNormal, Neinor ,Roboto, Helvetica, Arial, sans-serif`,
  fontSize: mobile ? 8 : 12,
  fontWeight: 100,
  width: '100%',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: selected ? theme.palette.primary.main : null
}))

export const Button = ({
  title,
  disabled,
  selected,
  mobile,
  onClick,
  children
}) => {
  return (
    <Root
      selected={disabled ? false : selected}
      disabled={disabled}
      mobile={mobile}
      onClick={disabled ? null : onClick}
    >
      {children}
      {/* <Image src={src} mobile={mobile} /> */}
      <Title mobile={mobile} selected={disabled ? false : selected}>
        {title}
      </Title>
    </Root>
  )
}
export default Button
