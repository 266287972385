import { useCallback } from 'react'
import { styled, alpha } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import CloseButton from '../Common/CloseButton'

import {
  boundsSelector,
  dialogIdSelector,
  filterActiveSelector,
  setDialogIdAction,
  setFilterActiveAction
} from '../../modules/mainStore'
import useH3DStore from '../../modules/useH3DStore'
import { getCurrentPromotion } from '../../modules/promotionStore'

const Root = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'narrow'
})(({ theme, narrow }) => ({
  fontFamily: `NeinorNormal ,Roboto, Helvetica, Arial, sans-serif`,
  position: 'absolute',
  zIndex: theme.zIndex.overlay,
  bottom: '50%',
  left: '50%',
  transform: 'translate(-50%,50%)',
  boxSizing: 'border-box',
  padding: 40,
  width: 1200,
  maxWidth: '96%',
  height: 800,
  maxHeight: '96%',
  display: 'flex',
  flexFlow: narrow ? 'column' : 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.primary.light, 0.9),
  borderRadius: 0
}))

export const Dialog = () => {
  const dialogId = useH3DStore(dialogIdSelector)
  const setDialogId = useH3DStore(setDialogIdAction)
  const promotion = useH3DStore(getCurrentPromotion)
  const bounds = useH3DStore(boundsSelector)
  const narrow = bounds?.width > 0 && bounds?.width < 720

  const handleClose = useCallback(() => {
    setDialogId('')
  }, [setDialogId])

  return promotion && dialogId ? (
    <Root elevation={4} narrow={narrow}>
      <CloseButton onClick={handleClose} />
      <iframe
        title={promotion.iframes[dialogId].title}
        seamless
        frameBorder={0}
        style={{ width: '100%', height: '100%' }}
        src={promotion.iframes[dialogId].src}
      />
    </Root>
  ) : null
}
export default Dialog
