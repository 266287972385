import useH3DStore from "../../../modules/useH3DStore";
import { getCurrentModel } from "../../../modules/modelStore";
import { rgb2Hex_3 } from "../../../utils/utils";

export const HemisphereLight = () => {
  const currentModel = useH3DStore(getCurrentModel);

  return currentModel?.hemisphereLight.on ? (
    <hemisphereLight
      intensity={currentModel.hemisphereLight.intensity}
      skyColor={rgb2Hex_3(
        currentModel.hemisphereLight.skyColor.r * 255,
        currentModel.hemisphereLight.skyColor.g * 255,
        currentModel.hemisphereLight.skyColor.b * 255
      )}
      groundColor={rgb2Hex_3(
        currentModel.hemisphereLight.groundColor.r * 255,
        currentModel.hemisphereLight.groundColor.g * 255,
        currentModel.hemisphereLight.groundColor.b * 255
      )}
    />
  ) : null;
};

export default HemisphereLight;
