import * as React from 'react'

const PantallaIcon = ({
  primaryColor = '#000',
  secondaryColor = '#F00',
  ...props
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" {...props}>
    <path
      style={{
        fill: 'none',
        stroke: primaryColor,
        strokeWidth: 5.869,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeMiterlimit: 4,
        strokeDasharray: 'none',
        strokeOpacity: 1
      }}
      d="M26.471 25.252h77.322v78.019H26.471z"
    />
    <path
      style={{
        fill: 'none',
        stroke: secondaryColor,
        strokeWidth: 5.9,
        strokeLinecap: 'round',
        strokeLinejoin: 'miter',
        strokeOpacity: 1,
        strokeMiterlimit: 4,
        strokeDasharray: 'none'
      }}
      d="m71.053 58.166 20.55-20.898h.174"
    />
    <path
      style={{
        fill: 'none',
        stroke: secondaryColor,
        strokeWidth: 5.9,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeOpacity: 1,
        strokeMiterlimit: 4,
        strokeDasharray: 'none'
      }}
      d="m91.951 51.722-.174-14.454-14.629-.174"
    />
    <path
      style={{
        fill: 'none',
        stroke: secondaryColor,
        strokeWidth: 5.9,
        strokeLinecap: 'round',
        strokeLinejoin: 'miter',
        strokeOpacity: 1,
        strokeMiterlimit: 4,
        strokeDasharray: 'none'
      }}
      d="M59.385 69.66 38.487 90.21"
    />
    <path
      style={{
        fill: 'none',
        stroke: secondaryColor,
        strokeWidth: 5.9,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeOpacity: 1,
        strokeMiterlimit: 4,
        strokeDasharray: 'none'
      }}
      d="m38.139 76.103.348 14.107 14.28.174"
    />
  </svg>
)

export default PantallaIcon
