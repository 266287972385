import { styled } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'

import PanZoomContainer from './PanZoomContainer'

import usePanZoom from './modules/usePanZoom'
import {
  pz_scaleSelector,
  pz_setChildSizeAction,
  pz_translationSelector
} from './modules/panZoomStore'

const Root = styled(PanZoomContainer, {
  shouldForwardProp: (prop) => prop !== 'src'
})(({ theme, src }) => ({
  backgroundColor: 'grey',
  backgroundImage: `url("${src}")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center'
}))

export const PanZoomImageBase = ({
  scale = 1,
  translation = { x: 0, y: 0 },
  src,
  onImageSize,
  onLoading,
  onLoaded,
  onError,
  ...props
}) => {
  const [error, setError] = useState(false)
  const lastImageSrc = useRef('')
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
  const image = useRef(new Image())

  useEffect(() => {
    image.current.onload = (e) => {
      const size = {
        width: e.target.naturalWidth,
        height: e.target.naturalHeight
      }
      setImageSize(size)
      setError(false)
      onImageSize?.(size)
      onLoading?.(false)
      onLoaded?.(true)
    }
    image.current.onerror = (e) => {
      console.log(e)
      onError?.()
      setError(true)
    }
  }, [onImageSize, onLoading, onLoaded, onError])

  // Cambio de imagen
  useEffect(() => {
    if (lastImageSrc.current !== src) {
      image.current.src = src
      lastImageSrc.current = src
      onLoading?.(true)
    }
  }, [src, onImageSize, onLoading])

  // console.log(src)
  return error ? (
    <div>Image not found</div>
  ) : (
    <Root
      scale={scale}
      translation={translation}
      childSize={imageSize}
      src={src}
      {...props}
    />
  )
}

export const PanZoomImage = ({ ...props }) => {
  const scale = usePanZoom(pz_scaleSelector)
  const translation = usePanZoom(pz_translationSelector)
  const setChildSize = usePanZoom(pz_setChildSizeAction)

  return (
    <PanZoomImageBase
      scale={scale}
      translation={translation}
      onImageSize={setChildSize}
      {...props}
    />
  )
}
export default PanZoomImage
