import { styled } from '@mui/material/styles'
import Slider from '@mui/material/Slider'
import { formatNumber } from '../../utils/utils'

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative'
}))

const SSlider = styled(Slider)(({ theme }) => ({
  margin: 0,
  boxSizing: 'border-box',
  padding: '0 0px',
  width: 'calc(100% - 12px)',

  '& .MuiSlider-thumb': {
    width: 16,
    height: 16,
    '&:hover': {
      boxShadow: 'none'
    }
  },
  '& .MuiSlider-track': {
    backgroundColor: 'grey'
  },
  '& .MuiSlider-rail': {
    backgroundColor: 'grey'
  }
}))

const FilterSlider = ({ min, max, range, step, unit, onRangeChange }) => {
  return (
    <Root>
      <div
        style={{
          paddingRight: 24,
          fontSize: 16,
          fontWeight: 600,
          width: 180,
          textAlign: 'right'
        }}
      >{`${formatNumber(range[0])}${unit}`}</div>
      <SSlider
        color="secondary"
        size="small"
        min={min}
        max={max}
        step={step}
        value={range}
        onChange={onRangeChange}
      />
      <div
        style={{
          paddingLeft: 24,
          fontSize: 16,
          fontWeight: 600,
          width: 180,
          textAlign: 'left'
        }}
      >{`${formatNumber(Math.round(range[1]))}${unit}`}</div>
    </Root>
  )
}
export default FilterSlider
