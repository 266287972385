import { useCallback, useMemo } from "react";
import { Paper } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { infoPanelSelector, setInfoPanelAction } from "../../modules/mainStore";
import {
  currentHouseSelector,
  getCurrentPromotion,
} from "../../modules/promotionStore";
import useH3DStore from "../../modules/useH3DStore";

import CloseButton from "../Common/CloseButton";
import { findById } from "../../utils/utils";

const Root = styled(Paper)(({ theme }) => ({
  fontFamily: `NeinorNormal ,Roboto, Helvetica, Arial, sans-serif`,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: theme.zIndex.overlay,
  boxSizing: "border-box",
  // padding: '16px 48px 24px 24px',
  padding: "16px 0px 24px 0px",
  width: 400,
  maxWidth: "96%",
  minHeight: 80,
  maxHeight: 400,
  display: "flex",
  flexFlow: "column",
  backgroundColor: alpha(theme.palette.primary.light, 0.9),
  borderRadius: 0,
}));

const Title = styled("div")(({ theme }) => ({
  flex: "1 0 auto",
  // textTransform: 'uppercase',
  userSelect: "none",
  fontWeight: 600,
  fontSize: 20,
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: "0px 48px 16px 24px",
}));
const Subtitle = styled("div")(({ theme }) => ({
  flex: "1 0 auto",
  userSelect: "none",
  fontSize: 16,
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: "0px 48px 16px 24px",
  color: "grey",
}));

const Content = styled("div")(({ theme }) => ({
  userSelect: "none",
  fontSize: 12,
  fontWeight: 100,
  overflow: "auto",
  minHeight: 0,
  flex: "0 1 auto",
  color: "grey",
  padding: "0px 24px 0px 24px",
  ...theme.scrollbar,
}));

export const InfoPanel = ({ title, text }) => {
  const infoPanel = useH3DStore(infoPanelSelector);
  const setInfoPanel = useH3DStore(setInfoPanelAction);
  const currentHouse = useH3DStore(currentHouseSelector);
  const promotion = useH3DStore(getCurrentPromotion);

  const panel = useMemo(() => {
    return promotion ? findById(promotion.infoPanels, infoPanel) : null;
  }, [promotion, infoPanel]);

  const handleClose = useCallback(() => {
    setInfoPanel("");
  }, [setInfoPanel]);

  return !currentHouse && panel ? (
    <Root elevation={4}>
      <Title>{panel.title}</Title>
      <Subtitle>{panel.subtitle}</Subtitle>
      <Content dangerouslySetInnerHTML={{ __html: panel.content }}></Content>
      <CloseButton onClick={handleClose} />
    </Root>
  ) : null;
};
export default InfoPanel;
