import { OrbitControls } from '@react-three/drei'

import useH3DStore from '../../modules/useH3DStore'
import { getCurrentModel } from '../../modules/modelStore'

export const CameraControls = () => {
  const currentModel = useH3DStore(getCurrentModel)
  const inf = currentModel.camera.azimuth[0] === currentModel.camera.azimuth[1]

  return currentModel ? (
    <OrbitControls
      makeDefault
      enablePan={false}
      enableZoom={true}
      target={currentModel.camera.target}
      minPolarAngle={currentModel.camera.polar[0]}
      maxPolarAngle={currentModel.camera.polar[1]}
      minAzimuthAngle={inf ? Infinity : currentModel.camera.azimuth[0]}
      maxAzimuthAngle={inf ? Infinity : currentModel.camera.azimuth[1]}
      maxDistance={currentModel.camera.maxDistance}
      minDistance={currentModel.camera.minDistance}
      dampingFactor={0.25}
      // rotateSpeed={0.2}
      regress
    />
  ) : null
}
export default CameraControls
