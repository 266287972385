import { LinearProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { progressSelector } from '../../modules/mainStore'
import useH3DStore from '../../modules/useH3DStore'
import TopBar from '../TopBar/TopBar'

const MARGIN_TOP = 32
const Background = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: theme.zIndex.overlay + 1,
  // backgroundColor: theme.palette.primary.main
  backgroundColor: 'transparent'
}))

const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: theme.zIndex.overlay + 1,
  transform: `translate(-50%, -${MARGIN_TOP}px)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexFlow: 'column',
  backgroundColor: theme.palette.primary.main
}))

const SLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: 200,
  height: 2
}))

const Logo = styled('div', {
  shouldForwardProp: (prop) => prop !== 'image'
})(({ theme, image }) => ({
  boxSizing: 'border-box',
  width: 120,
  height: 120,
  margin: MARGIN_TOP,
  marginBottom: 0,

  backgroundImage: `url("${image}")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain'
}))

const StartView = () => {
  const progress = useH3DStore(progressSelector)

  return (
    <Background>
      <Root>
        <Logo image={'images/start_logo.png'} />
        <SLinearProgress
          color="secondary"
          variant="determinate"
          value={progress}
        />
      </Root>
    </Background>
  )
}
export default StartView
