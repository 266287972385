import * as THREE from 'three'

export const MaterialBasic2 = ({
  original,
  modified,
  map,
  alphaMap,
  backgroundHidden,
  envMap
}) => {
  return (
    <meshBasicMaterial
      map={map}
      alphaMap={alphaMap}
      alphaTest={modified?.alphaTest || 0}
      envMap={modified?.envMap && !backgroundHidden ? envMap : null}
      transparent={modified?.transparent || false}
      opacity={modified?.opacity || 1}
      reflectivity={modified?.reflectivity || 1}
      refractionRatio={modified?.refractionRatio || 0.98}
      combine={modified?.combine || THREE.MultiplyOperation}
      color-r={modified?.color ? modified.color.r : 1}
      color-g={modified?.color ? modified.color.g : 1}
      color-b={modified?.color ? modified.color.b : 1}
      side={modified?.side || THREE.DoubleSide}
    />
  )
}

export default MaterialBasic2
// import { useEffect } from 'react'
// import { useTexture } from '@react-three/drei'
// import * as THREE from 'three'

// const WithTexture = ({ original, modified, backgroundHidden, envMap }) => {
//   const texture = useTexture(modified.fullMap)

//   useEffect(() => {
//     if (texture) {
//       texture.wrapS = THREE.RepeatWrapping
//       texture.wrapT = THREE.RepeatWrapping
//       texture.needsUpdate = true
//     }
//   }, [texture])

//   return (
//     <meshBasicMaterial
//       map={texture}
//       envMap={modified.envMap && !backgroundHidden ? envMap : null}
//       transparent={modified.transparent}
//       opacity={modified.opacity}
//       reflectivity={modified.reflectivity}
//       refractionRatio={modified.refractionRatio}
//       combine={modified.combine}
//       color-r={modified.color.r}
//       color-g={modified.color.g}
//       color-b={modified.color.b}
//     />
//   )
// }
// const WithoutTexture = ({ original, modified, backgroundHidden, envMap }) => {
//   return (
//     <meshBasicMaterial
//       envMap={modified.envMap && !backgroundHidden ? envMap : null}
//       transparent={modified.transparent}
//       opacity={modified.opacity}
//       reflectivity={modified.reflectivity}
//       refractionRatio={modified.refractionRatio}
//       combine={modified.combine}
//       color-r={modified.color.r}
//       color-g={modified.color.g}
//       color-b={modified.color.b}
//     />
//   )
// }

// export const MaterialBasic = ({
//   original,
//   modified,
//   backgroundHidden,
//   envMap
// }) => {
//   // console.log(original, modified)
//   return modified ? (
//     modified.fullMap ? (
//       <WithTexture
//         original={original}
//         modified={modified}
//         backgroundHidden={backgroundHidden}
//         envMap={envMap}
//       />
//     ) : (
//       <WithoutTexture
//         original={original}
//         modified={modified}
//         backgroundHidden={backgroundHidden}
//         envMap={envMap}
//       />
//     )
//   ) : null
// }

// export default MaterialBasic
