import { forwardRef, useState } from "react";
import { Object3D } from "three";
import { rgb2Hex_3 } from "../../../utils/utils";

export const DirectionalLightBase = forwardRef(
  (
    {
      intensity,
      castShadow,
      color = { r: 1, g: 1, b: 1 },
      targetPosition = [0, 0, 0],
      position = [0, 1, 0],
      bias = -0.005,
      normalBias = 0,
      radius = 1,
      mapWidth = 512,
      mapHeight = 512,
      far,
      near,
      left,
      right,
      top,
      bottom,
    },
    ref
  ) => {
    const [target] = useState(() => new Object3D());

    return (
      <group>
        <directionalLight
          ref={ref}
          intensity={intensity}
          color={rgb2Hex_3(color.r * 255, color.g * 255, color.b * 255)}
          castShadow={castShadow}
          target={target}
          shadow-bias={bias}
          shadow-camera-far={far}
          shadow-camera-near={near}
          shadow-camera-left={left}
          shadow-camera-right={right}
          shadow-camera-top={top}
          shadow-camera-bottom={bottom}
          shadow-normalBias={normalBias}
          shadow-radius={radius}
          shadow-mapSize-width={mapWidth}
          shadow-mapSize-height={mapHeight}
          position={position}
        />
        <primitive object={target} position={targetPosition} />
      </group>
    );
  }
);
export default DirectionalLightBase;
