import { useTheme } from '@mui/material/styles'
import { useCallback } from 'react'
import {
  fullscreenSelector,
  setFullscreenAction
} from '../../modules/mainStore'
import useH3DStore from '../../modules/useH3DStore'
import PantallaIcon from '../Icons/PantallaIcon'
import Button from './Button'

export const FullscreenButton = ({ mobile }) => {
  const fullscreen = useH3DStore(fullscreenSelector)
  const setFullscreen = useH3DStore(setFullscreenAction)
  const theme = useTheme()

  const handleFullscreen = useCallback(() => {
    setFullscreen(!fullscreen)
  }, [fullscreen, setFullscreen])
  return (
    <Button
      title="Pantalla Completa"
      mobile={mobile}
      onClick={handleFullscreen}
      selected={fullscreen}
    >
      <PantallaIcon
        width={'auto'}
        height={'100%'}
        primaryColor={
          fullscreen
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText
        }
        secondaryColor={
          fullscreen ? theme.palette.primary.main : theme.palette.secondary.main
        }
      />
    </Button>
  )
}
export default FullscreenButton
