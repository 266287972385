import useH3DStore from "../../../modules/useH3DStore";
import { backgroundActiveSelector } from "../../../modules/mainStore";
import { SceneEquirectangularBackground } from "../SceneEquirectangularBackground";

export const Sky = ({ src, onLoad }) => {
  const backgroundActive = useH3DStore(backgroundActiveSelector);
  return (
    <SceneEquirectangularBackground
      src={src}
      visible={backgroundActive}
      onLoad={onLoad}
    />
  );
};
