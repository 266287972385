import { useEffect, useMemo, useRef, useState } from 'react'
import {
  ClampToEdgeWrapping,
  LinearFilter,
  LinearMipmapLinearFilter,
  LinearSRGBColorSpace,
  MirroredRepeatWrapping,
  RepeatWrapping,
  TextureLoader
} from 'three'

export const updateTextureValueAfterLoad = (
  t,
  magFilter = LinearFilter,
  minFilter = LinearMipmapLinearFilter
) => {
  t.wrapS = RepeatWrapping
  t.wrapT = RepeatWrapping
  t.colorSpace = LinearSRGBColorSpace
  // t.encoding = 3001
  t.magFilter = magFilter
  t.minFilter = minFilter
  t.needsUpdate = true
}

export const useLoadAllModelTextures = (nodes) => {
  const textureLoader = useRef(new TextureLoader())
  const [textures, setTextures] = useState(null)
  const loading = useRef(false)

  const texturesToLoad = useMemo(() => {
    return Object.keys(nodes).reduce((acc, nodeId) => {
      const node = nodes[nodeId]
      if (node.visible) {
        if (node.material.fullMap) {
          acc.push({
            id: node.id,
            src: node.material.fullMap,
            map: true,
            material: node.material
          })
        }
        if (node.material.fullAlphaMap) {
          acc.push({
            id: nodeId,
            src: node.material.fullAlphaMap,
            alphaMap: true,
            material: node.material
          })
        }
      }
      return acc
    }, [])
  }, [nodes])

  useEffect(() => {
    const loadData = async () => {
      if (!loading.current) {
        loading.current = true
        try {
          let ts = await Promise.all(
            texturesToLoad.map((i) => textureLoader.current.loadAsync(i.src))
          )
          ts = texturesToLoad.reduce((acc, i, idx) => {
            if (!acc[i.id]) {
              acc[i.id] = {}
            }
            if (i.map) {
              const t = ts[idx]
              updateTextureValueAfterLoad(
                t,
                i.material.mapMagFilter,
                i.material.mapMinFilter
              )
              acc[i.id].map = t
            }
            if (i.alphaMap) {
              const t = ts[idx]
              updateTextureValueAfterLoad(
                t,
                i.material.alphaMapMagFilter,
                i.material.alphaMapMinFilter
              )
              acc[i.id].alphaMap = t
            }
            return acc
          }, {})
          setTextures(ts)
        } catch (error) {
          console.log(error)
          setTextures({})
        }
      }
    }
    loadData()
  }, [textures, texturesToLoad])

  return textures
}
